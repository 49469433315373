import { ICellRendererParams } from 'ag-grid-community';

export enum GRID_BUTTON_TYPE {
  EDIT = 'edit',
  DELETE = 'delete',
}

export const GridImage = (props: ICellRendererParams) => {
  const buttonClicked = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (
      'callback' in props.colDef?.cellRendererParams &&
      typeof props.colDef?.cellRendererParams.callback === 'function'
    ) {
      props.colDef?.cellRendererParams.callback(props.node.data);
    } else {
      console.error('@@@ callback not found');
    }
  };

  return (
    <>
      {props.colDef && props.colDef.field && (
        <span>
          <div style={{ cursor: 'pointer' }} onClick={e => buttonClicked(e)}>
            <img
              src={props.node.data[props.colDef.field as string]}
              style={{ maxHeight: '100px' }}
            ></img>
          </div>
        </span>
      )}
    </>
  );
};
