import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import GridLayout from '../components/GridLayout';
import { QuestionaryForm } from '../components/Forms';
import { useGrid } from '../hooks/useGrid';
import {
  getAllQuestionFlowCallback,
  deleteQuestionFlowCallback,
  QuestionFlow,
  questionFlowsState,
  Modal,
} from '@laborability/commons';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../Routes';
import { OpenInNew } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { appBarState } from '../recoil/states/appBar';
import { GridImage } from '../components/GridImage';
import { LBTModal } from '@laborability/components';
import { ImageView } from '../components/ImageView';

export function QuestionaryPage() {
  const navigate = useNavigate();
  const getAllFlow = useRecoilCallback(getAllQuestionFlowCallback, []);
  const deleteFlow = useRecoilCallback(deleteQuestionFlowCallback, []);
  const setAppBarName = useSetRecoilState(appBarState);
  const [modal, setModal] = useState<Modal | null>(null);

  const { rowData, handleFetchAll, handleDelete } = useGrid<QuestionFlow>({
    state: questionFlowsState,
    onFetch: getAllFlow,
    onDelete: deleteFlow,
  });

  const columnDefs: ColDef[] = [
    {
      field: 'id',
      maxWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: false,
      headerName: 'ID',
    },
    {
      field: 'name',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Questionario',
    },
    {
      field: 'description',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Descrizione',
    },
    {
      field: 'step',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Step',
      cellRenderer: (data: ICellRendererParams) => data.data?.step ?? '-',
    },
    {
      field: 'image',
      filter: false,
      flex: 1,
      headerName: 'Immagine',
      width: 100,
      cellRenderer: GridImage,
      cellRendererParams: {
        callback: async (data: QuestionFlow) => {
          setModal({
            children: (
              <ImageView
                image={data?.image ?? ''}
                onClose={() => setModal(null)}
              />
            ),
            title: 'Immagine Categoria',
          });
        },
      },
    },
    {
      field: 'open',
      headerName: 'Apri',
      width: 100,
      cellRenderer: (data: ICellRendererParams) => (
        <Box
          style={{ cursor: 'pointer' }}
          onClick={() =>
            navigate(`/${APP_ROUTES.QUESTION_FLOW}/${data.data.id}`)
          }
        >
          <OpenInNew />
        </Box>
      ),
      pinned: 'right',
    },
  ];

  useEffect(() => {
    setAppBarName('Registrazione questionari');
  }, []);

  return (
    <>
      <GridLayout<QuestionFlow>
        tableId="questionary"
        pageName="Questionari"
        columnDefs={columnDefs}
        rowData={rowData}
        handleFetchAll={handleFetchAll}
        handleDelete={handleDelete}
        FormComponent={QuestionaryForm}
        recordNameAccessor={item => item.name ?? ''}
        rowHeight={100}
      />
      {!!modal && (
        <LBTModal open={!!modal} onXClose={() => setModal(null)} {...modal} />
      )}
    </>
  );
}
