import { Entity, EntityClass } from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const entityState = atom<Entity>({
  key: getUUID(),
  default: new EntityClass(),
});

export const entitiesState = atom<Entity[]>({
  key: getUUID(),
  default: [],
});

export const entityLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const entitiesLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
