import { IconsSize, IconsStyle } from '../../enums';

const IconArrowLeftComponent = ({
  size,
  style,
  color,
}: {
  size: IconsSize;
  style: IconsStyle;
  color: string;
}) => {
  return style === IconsStyle.OUTLINE ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9499 21.7367C17.0663 21.5014 16.9719 21.2154 16.7391 21.0978C12.5855 19.0002 10.3284 16.9281 8.24451 13.1723C7.84202 12.4436 7.84206 11.5561 8.24463 10.8275C10.3301 7.07175 12.5858 4.9997 16.7391 2.9022C16.9719 2.78459 17.0663 2.49856 16.9499 2.26333C16.8334 2.02811 16.5503 1.93276 16.3174 2.05038C12.0162 4.22255 9.61074 6.42018 7.42186 10.3623C6.85969 11.3796 6.85923 12.6196 7.4214 13.6368C9.60877 17.5792 12.0163 19.7775 16.3174 21.9496C16.5503 22.0672 16.8334 21.9719 16.9499 21.7367Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1713 22.2959C16.4203 22.9813 17.7636 21.8216 17.4553 20.5824C16.9519 18.5584 16.2221 15.1513 16.2221 12.5C16.2221 9.84717 16.9519 6.44164 17.4553 4.41759C17.7636 3.17838 16.4203 2.01872 15.1713 2.70411C11.3929 4.77712 9.08211 7.05359 6.96011 10.7743C6.34663 11.8467 6.34663 13.1517 6.96011 14.2257C9.08054 17.9464 11.3913 20.2214 15.1697 22.2959H15.1713Z"
        fill={color}
      />
    </svg>
  );
};

export default IconArrowLeftComponent;
