import { CallbackInterface } from 'recoil';
import {
  getRegioni,
  getProvince,
  getProvinceByRegionId,
  getComuni,
  getComuniByProvinceId,
} from '../actions';
import { setSnackbarError } from '../common';
import { Id } from '../types';
import {
  municipalitiesLoaderState,
  municipalitiesState,
  provincesLoaderState,
  provincesState,
  regionsLoaderState,
  regionsState,
} from '../state';

export const getRegioniCallback =
  ({ set }: CallbackInterface) =>
  async () => {
    set(regionsLoaderState, true);
    let res = undefined;
    try {
      res = await getRegioni();
      set(regionsState, res?.data ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(regionsLoaderState, false);
    return res;
  };

export const getProvinceCallback =
  ({ set }: CallbackInterface) =>
  async () => {
    set(provincesLoaderState, true);
    let res = undefined;
    try {
      res = await getProvince();
      set(provincesState, res?.data ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(provincesLoaderState, false);
    return res;
  };

export const getProvinceByRegionIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(provincesLoaderState, true);
    let res = undefined;
    try {
      res = await getProvinceByRegionId(params);
      set(provincesState, res?.data ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(provincesLoaderState, false);
    return res;
  };

export const getComuniCallback =
  ({ set }: CallbackInterface) =>
  async () => {
    set(municipalitiesLoaderState, true);
    let res = undefined;
    try {
      res = await getComuni();
      set(municipalitiesState, res?.data ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(municipalitiesLoaderState, false);
    return res;
  };

export const getComuniByProvinceIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(municipalitiesLoaderState, true);
    let res = undefined;
    try {
      res = await getComuniByProvinceId(params);
      set(municipalitiesState, res?.data ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(municipalitiesLoaderState, false);
    return res;
  };
