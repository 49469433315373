import {
  Accordion,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils';
import IconArrowDownComponent from '../Icons/IconArrowDownComponent';
import { IconsSize, IconsStyle } from '../../enums';
import LBTLabel from '../Label';

const StyledAccordion = styled(Accordion)<
  AccordionProps & {
    datatestid: string;
    bgColor?: string;
    'data-track': string;
  }
>(({ datatestid, bgColor, ...props }) => ({
  datatestid,
  'data-track': props['data-track'],
  '&.MuiAccordion-root': {
    margin: '0',
    'background-color': bgColor ? bgColor : 'transparent',
    'box-shadow': 'none',
    '&::before': { content: 'none' },
    'max-width': '680px',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)<
  AccordionSummaryProps & { datatestid: string }
>(({ datatestid }) => ({
  datatestid,
  '&.MuiAccordionSummary-root': {
    padding: '11px 14px 11px 0',
  },
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '0',
  },
  '&.MuiAccordionSummary-root.Mui-expanded': {
    'min-height': '0px',
    margin: '0px',
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)<
  AccordionDetailsProps & { datatestid: string }
>(({ datatestid }) => ({
  datatestid,
  '& *': {
    margin: '0',
  },
  '&.MuiAccordionDetails-root': {
    padding: '8px 16px 16px 16px',
  },
}));

export type LBTAccordionProps = Omit<AccordionProps, 'children'> & {
  datatestid?: string;
  uniqueKey: string;
  summary: string;
  details: React.ReactNode;
};

const LBTAccordion: React.FC<LBTAccordionProps> = ({
  datatestid = '',
  uniqueKey,
  summary,
  details,
  dangerouslySetInnerHTML,
  ...props
}: LBTAccordionProps) => {
  return (
    <StyledAccordion
      disableGutters
      data-track={datatestid}
      datatestid={`lbt-accordion-${datatestid}`}
      {...props}
    >
      <StyledAccordionSummary
        datatestid={`lbt-accordion-summary-${datatestid}`}
        expandIcon={
          <IconArrowDownComponent
            size={IconsSize.SMALL}
            color={COLORS.getInstance().BLACK}
            style={IconsStyle.OUTLINE}
          />
        }
        aria-controls={`${uniqueKey}-content`}
        id={uniqueKey}
        component="h4"
      >
        <LBTLabel variant="miscTitleSource" component="h4" textAlign="left">
          {summary}
        </LBTLabel>
      </StyledAccordionSummary>
      <StyledAccordionDetails
        datatestid={`lbt-accordion-details-${datatestid}`}
      >
        <LBTLabel
          variant="miscDescription"
          dangerouslySetInnerHTML={dangerouslySetInnerHTML}
          textAlign="left"
        >
          {details}
        </LBTLabel>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default LBTAccordion;
