import {
  ContinueFlowResponse,
  ContinueFlowResponseClass,
  QuestionPage,
  QuestionPageClass,
} from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const questionPageState = atom<QuestionPage>({
  key: getUUID(),
  default: new QuestionPageClass(),
});

export const questionPagesState = atom<QuestionPage[]>({
  key: getUUID(),
  default: [],
});

export const continueFlowState = atom<ContinueFlowResponse>({
  key: getUUID(),
  default: new ContinueFlowResponseClass(),
});

export const questionPageLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const questionPagesLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const continueFlowLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
