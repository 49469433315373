import { PATH, authDelete, authGetAll, authPost, authPut } from '../../http';
import { Benefit, GetFilter, Id, Post, Put } from '../types';

export const postBenefit = async (item: Post<Benefit>) => {
  return await authPost(`api/v1/${PATH.MISURE}/${PATH.BENEFITS}/`, item);
};

export const getBenefitById = async ({ id }: Id) => {
  return await authGetAll(
    `api/v1/${PATH.MISURE}/${PATH.PUBLIC}/${PATH.BENEFITS}/${id}`,
  );
};

export const getAllBenefits = async ({ skip, limit }: GetFilter) => {
  return await authGetAll(
    `api/v1/${PATH.MISURE}/${PATH.PUBLIC}/${PATH.BENEFITS}/`,
    { skip, limit },
  );
};

export const putBenefit = async (item: Put<Benefit>) => {
  return await authPut(`api/v1/${PATH.MISURE}/${PATH.BENEFITS}`, item);
};

export const deleteBenefit = async ({ id }: Id) => {
  return await authDelete(`api/v1/${PATH.MISURE}/${PATH.BENEFITS}/${id}`);
};
