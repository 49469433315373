export enum IconsStyle {
  FILLED = 'filled',
  OUTLINE = 'outline',
}

export enum IconsSize {
  LARGE = 24,
  MEDIUM = 20,
  SMALL = 16,
}
