import { Category } from '../recoil';

export const getUUID = () => {
  let d = new Date().getTime(); //Timestamp
  let d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const acceptedImageType = {
  accept: 'image/png, image/gif, image/jpeg, image/svg+xml',
};

const getParentCategories = (
  pAllCategories: Array<Category>,
  currentCategory: Category,
  level: number,
  mainParentId: number,
  orderedCategories: Array<Category>,
) => {
  // currentCategory.name =
  //   (level > 0 ? '|' : '') + '--'.repeat(level) + currentCategory.name;

  const exist = orderedCategories.find(
    (e: Category) => e.id === currentCategory.id,
  );
  !exist && orderedCategories.push(currentCategory);

  const subCategories = pAllCategories.filter(
    (c: Category) => c.parent_id === currentCategory.id,
  );
  if (subCategories.length > 0) {
    currentCategory.level = level;
    currentCategory.mainParentId = mainParentId;
    currentCategory.subCategories = subCategories;
    level++;
    subCategories.forEach((subCat: Category) => {
      getParentCategories(
        pAllCategories,
        subCat,
        level,
        mainParentId,
        orderedCategories,
      );
    });
  } else {
    currentCategory.level = level;
    currentCategory.mainParentId = mainParentId;
    currentCategory.subCategories = new Array<Category>();
  }
};

export const getAllCategoriesWithSubcategories = (
  originalCategories: Array<Category>,
) => {
  const copyOfCategories = JSON.parse(JSON.stringify(originalCategories));

  const categoriesWithSubcategories = copyOfCategories
    .slice()
    .filter((c: Category) => !c.parent_id);

  const orderedCategories = new Array<Category>();

  categoriesWithSubcategories.forEach((c: Category) => {
    getParentCategories(
      copyOfCategories,
      c,
      0,
      c.id as number,
      orderedCategories,
    );
  });

  return {
    originalCategories,
    copyOfCategories,
    categoriesWithSubcategories,
    orderedCategories,
  };
};

export function removeNullishOrEmptyFields(obj: any) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) => value !== null && value !== undefined && value !== '',
    ),
  );
}

export function getRealmFromUrl() {
  const hostname = window.location.hostname.split('.');
  console.log({
    hostname: hostname[0],
    split: hostname[0].split('-')[0],
    window,
  });
  if (hostname.length === 3) return hostname[0].split('-')[0];
  return undefined;
}

export function slugify(str: string) {
  return String(str)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
}

export function checkQuestionaryAccess(realm: string) {
  return !!process.env.REACT_APP_FE_QUESTIONARY_ACCESS?.split(', ').find(
    azienda => azienda === realm,
  );
}
