export enum GA4_EVENT_ACTION {
  PAGE_VIEW = 'page_view',
  CLICK_ITEM = 'click_item',
  FACEBOOK_ITEM = 'facebook_item',
}

export enum GA4_PAGE_BLOCK {
  MISIRA = 'misura',
  STATIC_ITEM = 'static_item',
}
