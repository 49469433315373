import { authPost, PATH, authGetAll, authPut, authDelete } from '../../http';
import {
  CompanyConfig,
  GetFilter,
  GetCompanyConfigurationByRealmname,
  Id,
  Post,
  Put,
  PutCompanyConfiguration,
} from '../types';

export const postCompanyConfiguration = async (item: Post<CompanyConfig>[]) => {
  return await authPost(`api/v1/${PATH.COMPANIES}/kv/all/`, item);
};

export const getCompanyConfiguration = async ({ id }: Id) => {
  return await authGetAll(`api/v1/${PATH.COMPANIES}/kv/${id}`);
};

export const getAllCompanyConfiguration = async ({
  id,
  skip,
  limit,
}: Id & GetFilter) => {
  return await authGetAll(`api/v1/${PATH.COMPANIES}/kv/all/${id}`, {
    skip,
    limit,
  });
};

export const getAllCompanyConfigurationByRealmName = async ({
  name,
  skip,
  limit,
}: GetCompanyConfigurationByRealmname) => {
  return await authGetAll(
    `api/v1/${PATH.COMPANIES}/kv/allbyrealmname/${name}`,
    {
      skip,
      limit,
    },
  );
};

export const putCompanyConfiguration = async (
  item: Put<PutCompanyConfiguration>,
) => {
  return await authPut(
    `api/v1/${PATH.COMPANIES}/all-kv`,
    item.config as any,
    item.id,
  );
};

export const deleteCompanyConfiguration = async ({ id }: Id) => {
  return await authDelete(`api/v1/${PATH.COMPANIES}/kv/${id}`);
};
