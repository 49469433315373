export type Tag = {
  id?: number;
  name?: string;
  description?: string;
  image?: string;
};

export class TagClass implements Tag {
  id = 0;
  name = '';
  description = '';
  image = '';
}
