import { authPost, PATH, authGetAll, authPut, authDelete } from '../../http';
import { DeleteMeasureRule, Id, Post, PostMeasureRule, Put } from '../types';

export const postMeasureRule = async (item: Post<PostMeasureRule>) => {
  const { measure_id, ...rest } = item;
  return await authPost(`api/v1/${PATH.MISURE}/${measure_id}/rules`, rest);
};

export const getAllMeasureRules = async ({ id }: Id) => {
  return await authGetAll(`api/v1/${PATH.MISURE}/${id}/rules`);
};

export const putMeasureRule = async (item: Put<PostMeasureRule>) => {
  const { measure_id, ...rest } = item;
  return await authPut(`api/v1/${PATH.MISURE}/${measure_id}/rules`, rest);
};

export const deleteMeasureRule = async ({
  measure_id,
  rule_id,
}: DeleteMeasureRule) => {
  return await authDelete(
    `api/v1/${PATH.MISURE}/${measure_id}/rules/${rule_id}`,
  );
};
