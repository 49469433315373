import { AttributeMeta, AttributeMetaClass } from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const attributeMetaState = atom<AttributeMeta>({
  key: getUUID(),
  default: new AttributeMetaClass(),
});

export const attributesMetaState = atom<AttributeMeta[]>({
  key: getUUID(),
  default: [],
});

export const attributeMetaLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const attributesMetaLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
