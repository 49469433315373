import { useMediaQuery, useTheme } from '@mui/material';

const useBreakpoint = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('mobile'));
  const isSmartphone = useMediaQuery(theme.breakpoints.only('smartphone'));
  const isTablet = useMediaQuery(theme.breakpoints.only('tablet'));
  const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'));

  const getBreakPointName = () => {
    if (isMobile) return 'mobile';
    if (isSmartphone) return 'smartphone';
    if (isTablet) return 'tablet';
    if (isDesktop) return 'desktop';
  };

  return {
    getBreakPointName,
    isMobile,
    isSmartphone,
    isTablet,
    isDesktop,
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

export default useBreakpoint;
