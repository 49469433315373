export type Category = {
  id?: number;
  name?: string;
  description?: string;
  icon?: string;
  image?: string;
  parent_id?: number;
  subCategories?: Category[];
  level?: number;
  mainParentId?: number;
  isLocked?: boolean;
};

export class CategoryClass implements Category {
  id = 0;
  name = '';
  description = '';
  icon = '';
  image = '';
  parent_id = undefined;
  subCategories = [];
  level = 0;
  mainParentId = 0;
  isLocked = false;
}
