import { authPost, PATH, authGetAll, authDelete, authPut } from '../../http';
import { Id, Post, PostQuestionPage, Put } from '../types';

export const postQuestionPage = async (item: Post<PostQuestionPage>) => {
  return await authPost(`api/v1/${PATH.QUESTIONARIES}/${PATH.PAGES}`, item);
};

export const getQuestionPageById = async ({ id }: Id) => {
  return await authGetAll(`api/v1/${PATH.QUESTIONARIES}/${PATH.PAGES}/${id}`);
};

export const getAllQuestionPage = async ({ id }: Id) => {
  return await authGetAll(`api/v1/${PATH.QUESTIONARIES}/${id}/${PATH.PAGES}`);
};

export const putQuestionPage = async (item: Put<PostQuestionPage>) => {
  return await authPut(`api/v1/${PATH.QUESTIONARIES}/${PATH.PAGES}`, item);
};

export const deleteQuestionPage = async ({ id }: Id) => {
  return await authDelete(`api/v1/${PATH.QUESTIONARIES}/${PATH.PAGES}/${id}`);
};
