import { IconsSize, IconsStyle } from '../../enums';

const IconWarningComponent = ({
  size = IconsSize.LARGE,
  style = IconsStyle.OUTLINE,
  color,
}: {
  size?: IconsSize;
  style?: IconsStyle;
  color?: string;
}) => {
  return style === IconsStyle.OUTLINE ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0007 7.46218C12.3088 7.46218 12.5586 7.7123 12.5586 8.02084V13.5117C12.5586 13.8202 12.3088 14.0703 12.0007 14.0703C11.6926 14.0703 11.4428 13.8202 11.4428 13.5117V8.02084C11.4428 7.7123 11.6926 7.46218 12.0007 7.46218Z"
        fill={color}
      />
      <path
        d="M12.5586 16.3145C12.5586 16.006 12.3088 15.7559 12.0007 15.7559C11.6926 15.7559 11.4428 16.006 11.4428 16.3145V17.3042C11.4428 17.6127 11.6926 17.8628 12.0007 17.8628C12.3088 17.8628 12.5586 17.6127 12.5586 17.3042V16.3145Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.63648 5.10136C10.0075 2.29955 13.9935 2.29955 15.3645 5.10136L21.6611 17.9713C21.801 18.2579 21.9696 18.6423 21.9963 19.0445C22.0261 19.4917 21.8788 19.9476 21.439 20.2982C21.0328 20.6224 20.4694 20.8779 19.8321 21.0841C19.1866 21.2929 18.4265 21.4631 17.5974 21.5977C15.939 21.867 13.9653 22 12.0005 22C10.0356 22 8.06195 21.867 6.40351 21.5977C5.57448 21.4631 4.81435 21.2929 4.1688 21.0841C3.53155 20.8779 2.96817 20.6224 2.56194 20.2982C2.12381 19.949 1.97401 19.4957 2.0036 19.0472C2.03013 18.645 2.19932 18.2608 2.34148 17.9712L8.63648 5.10136ZM14.3624 5.59294C13.3996 3.62544 10.6013 3.62544 9.63852 5.59297L3.34353 18.4628C3.20678 18.7413 3.12794 18.9554 3.11703 19.1208C3.10916 19.2402 3.13232 19.3248 3.2569 19.424C3.5106 19.6265 3.92805 19.832 4.51184 20.0209C5.08749 20.2071 5.78907 20.3661 6.58212 20.4948C8.16783 20.7523 10.0798 20.8827 12.0005 20.8827C13.9212 20.8827 15.8331 20.7523 17.4188 20.4948C18.2119 20.3661 18.9134 20.2071 19.4891 20.0209C20.0729 19.832 20.4901 19.6268 20.7438 19.4243C20.8666 19.3263 20.8911 19.241 20.8829 19.1187C20.8718 18.9515 20.7931 18.7376 20.6588 18.4623L14.3624 5.59294Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.50587 5.17974C9.93023 2.27342 14.0726 2.27342 15.4954 5.17974L21.6442 17.7297C21.7812 18.013 21.9649 18.4224 21.9945 18.8645C22.0169 19.135 21.9708 19.4068 21.8607 19.6549C21.7505 19.903 21.5797 20.1194 21.364 20.2842C20.936 20.6267 20.3553 20.8866 19.7217 21.0906C19.0757 21.2992 18.3207 21.4673 17.5035 21.6012C15.869 21.8658 13.9293 21.9966 12.0006 21.9966C10.0734 21.9966 8.13226 21.8658 6.49775 21.6012C5.74722 21.4861 5.00627 21.3155 4.28104 21.0906C3.64747 20.8866 3.06683 20.6267 2.63718 20.2842C2.42169 20.1199 2.2509 19.904 2.14047 19.6565C2.03005 19.409 1.98353 19.1378 2.00517 18.8676C2.0363 18.4255 2.21999 18.0146 2.35854 17.7313L8.50742 5.17974H8.50587ZM12.0006 6.54495C12.3103 6.54495 12.6072 6.66795 12.8262 6.88691C13.0451 7.10586 13.1681 7.40282 13.1681 7.71246V12.7561C13.1681 13.0657 13.0451 13.3627 12.8262 13.5817C12.6072 13.8006 12.3103 13.9236 12.0006 13.9236C11.691 13.9236 11.394 13.8006 11.1751 13.5817C10.9561 13.3627 10.8331 13.0657 10.8331 12.7561V7.71246C10.8331 7.40282 10.9561 7.10586 11.1751 6.88691C11.394 6.66795 11.691 6.54495 12.0006 6.54495ZM12.0006 15.2016C12.3103 15.2016 12.6072 15.3247 12.8262 15.5436C13.0451 15.7626 13.1681 16.0595 13.1681 16.3692V17.2829C13.1681 17.5926 13.0451 17.8895 12.8262 18.1085C12.6072 18.3274 12.3103 18.4504 12.0006 18.4504C11.691 18.4504 11.394 18.3274 11.1751 18.1085C10.9561 17.8895 10.8331 17.5926 10.8331 17.2829V16.3692C10.8331 16.0595 10.9561 15.7626 11.1751 15.5436C11.394 15.3247 11.691 15.2016 12.0006 15.2016Z"
        fill={color}
      />
    </svg>
  );
};

export default IconWarningComponent;
