import { ButtonProps } from '@mui/material';
import React from 'react';
import LBTLabel from '../Label';
import { COLORS } from '../../utils';
import LBTCheckbox from '../Checkbox';
import { StyledBox } from './Button';

export type LBTCheckboxButtonProps = ButtonProps & {
  icon?: React.ReactNode;
  label: string;
  checked: boolean;
  handleChange: (val: boolean) => void;
  datatestid?: string;
};

export default function LBTCheckboxButton({
  label,
  checked,
  handleChange,
  datatestid = '',
  ...props
}: LBTCheckboxButtonProps) {
  return (
    <StyledBox
      datatestid={`lbt-select-${datatestid}`}
      data-track={datatestid}
      onClick={() => handleChange(!checked)}
      sx={{
        backgroundColor: !checked
          ? COLORS.getInstance().WHITE
          : COLORS.getInstance().PRIMARY_IPERLIGHT,
      }}
      {...props}
    >
      <LBTLabel variant={'inputFormLabel'}>{label}</LBTLabel>
      <LBTCheckbox checked={checked} onChange={() => {}} />
    </StyledBox>
  );
}
