import { CallbackInterface } from 'recoil';
import { setSnackbarSuccess, setSnackbarError } from '../common';
import { Id, Post, PostQuestion, Put } from '../types';
import {
  questionLoaderState,
  questionState,
  questionsLoaderState,
  questionsState,
} from '../state';
import {
  postQuestion,
  putQuestion,
  deleteQuestion,
  getQuestionById,
  getAllQuestion,
} from '../actions';

export const postQuestionCallback =
  ({ set }: CallbackInterface) =>
  async (params: Post<PostQuestion>) => {
    set(questionLoaderState, true);
    let res = undefined;
    try {
      res = await postQuestion(params);
      set(questionState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Domanda creata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionLoaderState, false);
    return res;
  };

export const getQuestionByIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(questionLoaderState, true);
    let res = undefined;
    try {
      res = await getQuestionById(params);
      set(questionState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionLoaderState, false);
    return res;
  };

export const getAllQuestionCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(questionsLoaderState, true);
    let res = undefined;
    try {
      res = await getAllQuestion(params);
      set(questionsState, res?.data?.items ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionsLoaderState, false);
    return res;
  };

export const putQuestionCallback =
  ({ set }: CallbackInterface) =>
  async (params: Put<PostQuestion>) => {
    set(questionLoaderState, true);
    let res = undefined;
    try {
      res = await putQuestion(params);
      set(questionState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Domanda modificata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionLoaderState, false);
    return res;
  };

export const deleteQuestionCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(questionLoaderState, true);
    let res = undefined;
    try {
      res = await deleteQuestion(params);
      if (res && res.data)
        setSnackbarSuccess(set, 'Domanda cancellata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionLoaderState, false);
    return res;
  };
