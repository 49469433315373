import { Contract, ContractClass } from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const contractState = atom<Contract>({
  key: getUUID(),
  default: new ContractClass(),
});

export const contractsState = atom<Contract[]>({
  key: getUUID(),
  default: [],
});

export const contractLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const contractsLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
