import { authPost, PATH, authGetAll, authDelete, authPut } from '../../http';
import {
  Id,
  Post,
  ActionRule,
  Put,
  GetActionRule,
  QuestionRule,
  GetQuestionRule,
} from '../types';

export const postActionRule = async (item: Post<ActionRule>) => {
  const { action_id, ...rest } = item;
  return await authPost(
    `api/v1/${PATH.QUESTIONARIES}/${PATH.ACTIONS}/${action_id}/${PATH.RULES}`,
    rest,
  );
};

export const postQuestionRule = async (item: Post<QuestionRule>) => {
  const { question_id, ...rest } = item;
  return await authPost(
    `api/v1/${PATH.QUESTIONARIES}/${PATH.QUESTIONS}/${question_id}/${PATH.RULES}`,
    rest,
  );
};

export const getActionRuleById = async ({ action_id, id }: GetActionRule) => {
  return await authGetAll(
    `api/v1/${PATH.QUESTIONARIES}/${PATH.ACTIONS}/${action_id}/${PATH.RULES}/${id}`,
  );
};

export const getQuestionRuleById = async ({
  question_id,
  id,
}: GetQuestionRule) => {
  return await authGetAll(
    `api/v1/${PATH.QUESTIONARIES}/${PATH.QUESTIONS}/${question_id}/${PATH.RULES}/${id}`,
  );
};

export const getAllActionRule = async ({ id }: Id) => {
  return await authGetAll(
    `api/v1/${PATH.QUESTIONARIES}/${PATH.ACTIONS}/${id}/${PATH.RULES}`,
  );
};

export const getAllQuestionRule = async ({ id }: Id) => {
  return await authGetAll(
    `api/v1/${PATH.QUESTIONARIES}/${PATH.QUESTIONS}/${id}/${PATH.RULES}`,
  );
};

export const putActionRule = async (item: Put<ActionRule>) => {
  return await authPut(
    `api/v1/${PATH.QUESTIONARIES}/${PATH.RULES}`,
    item,
    `${item.id}/action`,
  );
};

export const putQuestionRule = async (item: Put<QuestionRule>) => {
  return await authPut(
    `api/v1/${PATH.QUESTIONARIES}/${PATH.RULES}`,
    item,
    `${item.id}/question`,
  );
};

export const deleteActionRule = async ({ action_id, id }: GetActionRule) => {
  return await authDelete(
    `api/v1/${PATH.QUESTIONARIES}/${PATH.ACTIONS}/${action_id}/${PATH.RULES}/${id}`,
  );
};

export const deleteQuestionRule = async ({
  question_id,
  id,
}: GetQuestionRule) => {
  return await authDelete(
    `api/v1/${PATH.QUESTIONARIES}/${PATH.QUESTIONS}/${question_id}/${PATH.RULES}/${id}`,
  );
};
