import { Ente, EnteClass } from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const agencyState = atom<Ente>({
  key: getUUID(),
  default: new EnteClass(),
});

export const agenciesState = atom<Ente[]>({
  key: getUUID(),
  default: [],
});

export const agencyLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const agenciesLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
