import { Benefit, BenefitClass } from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const benefitState = atom<Benefit>({
  key: getUUID(),
  default: new BenefitClass(),
});

export const benefitsState = atom<Benefit[]>({
  key: getUUID(),
  default: [],
});

export const benefitLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const benefitsLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
