import { CallbackInterface } from 'recoil';
import { GetMetric } from '../types';
import { getMetric } from '../actions';
import { setSnackbarError } from '../common';
import { metricLoaderState, metricState } from '../state';

export const getMetricCallback =
  ({ set }: CallbackInterface) =>
  async (params: GetMetric) => {
    set(metricLoaderState, true);
    let res = undefined;
    try {
      res = await getMetric(params);
      set(metricState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(metricLoaderState, false);
    return res;
  };
