import { IconsSize } from '../../enums';

export default function IconCompass({
  size,
  color,
}: {
  size: IconsSize;
  color?: string;
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.07483 5.07484C6.6941 3.45557 9.05097 2.625 12 2.625C14.949 2.625 17.3059 3.45558 18.9252 5.07484C20.5444 6.6941 21.375 9.05098 21.375 12C21.375 14.949 20.5444 17.3059 18.9252 18.9252C17.3059 20.5444 14.949 21.375 12 21.375C9.05097 21.375 6.69409 20.5444 5.07483 18.9252C3.45557 17.3059 2.625 14.949 2.625 12C2.625 9.05097 3.45557 6.6941 5.07483 5.07484ZM5.60516 5.60517C4.16443 7.0459 3.375 9.18903 3.375 12C3.375 14.811 4.16442 16.9541 5.60516 18.3948C7.0459 19.8356 9.18902 20.625 12 20.625C14.811 20.625 16.9541 19.8356 18.3948 18.3948C19.8356 16.9541 20.625 14.811 20.625 12C20.625 9.18903 19.8356 7.04591 18.3948 5.60517C16.9541 4.16443 14.811 3.375 12 3.375C9.18903 3.375 7.0459 4.16443 5.60516 5.60517ZM9.882 10.4122L13.5877 14.118C12.0306 15.0218 10.334 15.6178 8.74076 15.4559C8.63619 15.4452 8.55491 15.364 8.54427 15.2594C8.38248 13.666 8.9782 11.9693 9.882 10.4122ZM14.118 13.5877L10.4123 9.88182C11.9694 8.97802 13.666 8.38225 15.2592 8.54413C15.3638 8.55477 15.4451 8.63605 15.4557 8.74065C15.6175 10.334 15.0218 12.0305 14.118 13.5877ZM9.60971 9.48968C11.3299 8.41733 13.3586 7.59715 15.3351 7.79798C15.7939 7.84461 16.1553 8.20604 16.2019 8.66486C16.4026 10.6413 15.5825 12.6701 14.5101 14.3903C14.4798 14.4389 14.4389 14.4799 14.3903 14.5101C12.6702 15.5825 10.6414 16.4029 8.66494 16.202C8.20609 16.1554 7.84472 15.794 7.79811 15.3351C7.59741 13.3587 8.41746 11.3298 9.48987 9.60952C9.52015 9.56095 9.56114 9.51996 9.60971 9.48968Z"
        fill={color}
      />
    </svg>
  );
}
