import { authPost, PATH, authGetAll, authPut, authDelete } from '../../http';
import { Contract, Id, Post, Put } from '../types';

export const postContract = async (item: Post<Contract>) => {
  return await authPost(`api/v1/${PATH.MISURE}/${PATH.CONTRACTS}/`, item);
};

export const getContractById = async ({ id }: Id) => {
  return await authGetAll(
    `api/v1/${PATH.MISURE}/${PATH.PUBLIC}/${PATH.CONTRACTS}/${id}`,
  );
};

export const getAllContracts = async () => {
  return await authGetAll(
    `api/v1/${PATH.MISURE}/${PATH.PUBLIC}/${PATH.CONTRACTS}/`,
  );
};

export const putContract = async (item: Put<Contract>) => {
  return await authPut(`api/v1/${PATH.MISURE}/${PATH.CONTRACTS}`, item);
};

export const deleteContract = async ({ id }: Id) => {
  return await authDelete(`api/v1/${PATH.MISURE}/${PATH.CONTRACTS}/${id}`);
};
