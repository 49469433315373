import { Category, CategoryClass } from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const categoryState = atom<Category>({
  key: getUUID(),
  default: new CategoryClass(),
});

export const categoriesState = atom<Category[]>({
  key: getUUID(),
  default: [],
});

export const categoryLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const categoriesLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
