import { CardContent, CardContentProps, Stack } from '@mui/material';
import Card, { CardProps } from '@mui/material/Card';
import styled from 'styled-components';
import { IconsSize, IconsStyle } from '../../enums';
import { COLORS } from '../../utils';
import LBTChip from '../Chip';
import { IconRocketComponent } from '../Icons';
import LBTLabel from '../Label';
import './styles.css';

export enum CardVariant {
  OUTLINED = 'outlined',
  CONTAINED = 'contained',
  COLORED = 'colored',
  NEUTRAL = 'neutral',
  WHITE = 'white',
}

export type LBTCardProps = CardProps & {
  title?: string;
  description?: string;
  tag?: string;
  news: boolean;
  expired: boolean;
  expiringSoon: boolean;
  upcoming: boolean;
  backgroundColor?: string;
  datatestid?: string;
  cardVariant?: CardVariant;
};

const StyledCard = styled(Card)<
  CardProps & {
    backgroundColor?: string;
    cardVariant?: CardVariant;
    datatestid?: string;
    'data-track': string;
  }
>(({ backgroundColor, datatestid, cardVariant, theme, ...props }) => ({
  backgroundColor,
  datatestid,
  theme,
  'data-track': props['data-track'],
  borderWidth: 0,
  ...(cardVariant === CardVariant.OUTLINED && {
    '&.MuiCard-root': {
      outline: `1px solid ${COLORS.getInstance().BW_GREYS_PALE_MEDIUM}`,
      backgroundColor: COLORS.getInstance().WHITE,
      width: '100%',
      borderRadius: '12px',
      padding: '24px 16px 16px 16px',
      cursor: !!props.onClick ? 'pointer' : 'auto',
      '&:hover': {
        outline: `1px solid ${COLORS.getInstance().BW_GREYS_MEDIUM}`,
      },
      '&:active': {
        outline: `2px solid ${COLORS.getInstance().BW_GREYS_DARK}`,
        boxShadow:
          '0px 1px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)',
      },
    },
  }),
  ...(cardVariant === CardVariant.CONTAINED && {
    '&.MuiCard-root': {
      backgroundColor: `${COLORS.getInstance().PRIMARY_MAIN}0A`,
      width: '100%',
      borderRadius: '12px',
      padding: '24px 16px 16px 16px',
      cursor: !!props.onClick ? 'pointer' : 'auto',
    },
    '&:hover': {
      backgroundColor: `${COLORS.getInstance().PRIMARY_MAIN}26`,
    },
    '&:active': {
      outline: `1px solid ${COLORS.getInstance().PRIMARY_DARK}`,
      boxShadow:
        '0px 1px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)',
    },
  }),
  ...(cardVariant === CardVariant.NEUTRAL && {
    '&.MuiCard-root': {
      backgroundColor: `${COLORS.getInstance().BW_GREYS_IPERLIGHT}`,
      width: '100%',
      borderRadius: '12px',
      padding: '24px 16px 16px 16px',
      cursor: !!props.onClick ? 'pointer' : 'auto',
    },
    '&:hover': {
      backgroundColor: `${COLORS.getInstance().NEGATIVE}`,
    },
    '&:active': {
      outline: `1px solid ${COLORS.getInstance().BW_GREYS_MEDIUM_LIGHT}`,
      boxShadow:
        '0px 1px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)',
    },
  }),
  ...(cardVariant === CardVariant.WHITE && {
    '&.MuiCard-root': {
      backgroundColor: `${COLORS.getInstance().WHITE}`,
      width: '100%',
      borderRadius: '12px',
      padding: '24px 16px 16px 16px',
      cursor: !!props.onClick ? 'pointer' : 'auto',
    },
    '&:hover': {
      outline: `1px solid ${COLORS.getInstance().BW_GREYS_LIGHT}`,
    },
    '&:active': {
      outline: `1px solid ${COLORS.getInstance().BW_GREYS_MEDIUM_LIGHT}`,
      boxShadow:
        '0px 1px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)',
    },
  }),
}));

const StyledCardContent = styled(CardContent)<CardContentProps>(() => ({
  '&.MuiCardContent-root': {
    padding: '24px 0px 24px 0px',
  },
}));

const LBTCard: React.FC<LBTCardProps> = ({
  datatestid = '',
  title = '',
  description = '',
  tag,
  news,
  expired,
  expiringSoon,
  upcoming,
  backgroundColor = COLORS.getInstance().WHITE,
  cardVariant = CardVariant.OUTLINED,
  ...props
}: LBTCardProps) => {
  const getChip = () => {
    const color = expired ? 'error' : expiringSoon ? 'warning' : 'primary';
    const label = expired
      ? 'Scaduto'
      : expiringSoon
        ? 'In scadenza'
        : 'In arrivo';
    return (
      <>
        {news && (
          <LBTChip
            datatestid={datatestid}
            label="Novità"
            variant="outlined"
            color={color}
            icon={
              <IconRocketComponent
                size={IconsSize.SMALL}
                style={IconsStyle.OUTLINE}
                color={COLORS.getInstance().PRIMARY_SUPERDARK}
              />
            }
          />
        )}
        {(upcoming || expired || expiringSoon) && (
          <LBTChip
            datatestid={datatestid}
            label={label}
            variant="filled"
            color={color}
          />
        )}
      </>
    );
  };

  return (
    <StyledCard
      elevation={0}
      backgroundColor={backgroundColor}
      datatestid={`lbt-card-${datatestid}`}
      data-track={datatestid}
      cardVariant={cardVariant}
      component="button"
      {...props}
    >
      <Stack direction="column" height="100%">
        <LBTLabel
          datatestid={datatestid}
          textAlign="left"
          variant="miscTitleSource"
          component="h4"
        >
          {title}
        </LBTLabel>
        <StyledCardContent>
          <LBTLabel
            datatestid={datatestid}
            textAlign="left"
            className="truncate-text"
            variant="miscDescription"
          >
            {description}
          </LBTLabel>
        </StyledCardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop="auto"
        >
          <LBTLabel
            datatestid={datatestid}
            color={COLORS.getInstance().BW_GREYS_JET_BLACK}
            textAlign="left"
            variant="smallCaps"
            component="em"
            fontStyle="normal"
          >
            {tag}
          </LBTLabel>
          <Stack direction="row" alignItems="right" spacing="8px">
            {getChip()}
          </Stack>
        </Stack>
      </Stack>
    </StyledCard>
  );
};

export default LBTCard;
