import { useEffect, useState } from 'react';
import { appBarState } from '../../recoil/states/appBar';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  COLORS,
  LBTButton,
  LBTButtonFilter,
  LBTProgressSpinner,
  LBTSelect,
  LBTSpacer,
} from '@laborability/components';
import {
  Metric,
  MetricClass,
  UserRole,
  companiesState,
  currentUserState,
  getAllCompaniesCallback,
  getMetricCallback,
  getUserProfileCallback,
} from '@laborability/commons';
import dayjs, { Dayjs } from 'dayjs';
import 'chart.js/auto';
import DateRange from './DateRange';
import { TABS, DateAggregationEnum, ANALYTIC_METRIC_LABEL } from './types';
import Chart from './Chart';

export const Home = () => {
  const [tabId, setTabId] = useState<number>(1);
  const currentTab = TABS.find(tab => tab.id === tabId)!;
  const companies = useRecoilValue(companiesState);
  const currentUser = useRecoilValue(currentUserState);
  const getAllCompanies = useRecoilCallback(getAllCompaniesCallback, []);
  const getUserMe = useRecoilCallback(getUserProfileCallback, []);
  const [dateAggregation, setDateAggregation] = useState<DateAggregationEnum>(
    DateAggregationEnum.day,
  );
  const dateAggregationDefaultGap =
    dateAggregation === DateAggregationEnum.day ? 30 : 12;
  const [startDate, setStartDate] = useState<Dayjs>(
    dayjs().subtract(dateAggregationDefaultGap, dateAggregation),
  );
  const [endDate, setEndDate] = useState<Dayjs>(dayjs());
  const [comparisonStartDate, setComparisonStartDate] = useState<Dayjs | null>(
    null,
  );
  const [comparisonEndDate, setComparisonEndDate] = useState<Dayjs | null>(
    null,
  );
  const [currentMetric, setCurrentMetric] = useState(currentTab.metrics[0]);
  const [companyId, setCompanyId] = useState<number | null>(null);
  const getMetric = useRecoilCallback(getMetricCallback, []);
  const setAppBarName = useSetRecoilState(appBarState);
  const [metricData, setMetricData] = useState<Metric>(new MetricClass());
  const [comparisonMetricData, setComparisonMetricData] =
    useState<Metric | null>(null);
  const [metricLoader, setMetricLoader] = useState<number>(0);

  const getMetricData = async () => {
    if (!companyId && !currentUser.companies?.length) return;
    setMetricLoader(val => val + 1);
    const res = await getMetric({
      metric: currentMetric,
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      company_id: companyId,
    });
    setMetricLoader(val => val - 1);
    if (!res || !res?.data) return;
    setMetricData(res.data);
  };
  const getComparisonMetricData = async () => {
    if (!companyId && !currentUser.companies?.length) return;
    if (!comparisonStartDate || !comparisonEndDate)
      return setComparisonMetricData(null);
    setMetricLoader(val => val + 1);
    const res = await getMetric({
      metric: currentMetric,
      start_date: comparisonStartDate.format('YYYY-MM-DD'),
      end_date: comparisonEndDate.format('YYYY-MM-DD'),
      company_id: companyId,
    });
    setMetricLoader(val => val - 1);
    if (!res || !res?.data) return;
    setComparisonMetricData(res.data);
  };

  useEffect(() => {
    setAppBarName('BO App');
    getAllCompanies();
    if (!currentUser.companies) {
      getUserMe();
    }
  }, []);

  useEffect(() => {
    getMetricData();
  }, [currentMetric, startDate, endDate, companyId, currentUser]);
  useEffect(() => {
    getComparisonMetricData();
  }, [currentMetric, comparisonStartDate, comparisonEndDate, companyId]);
  useEffect(() => {
    setStartDate(
      endDate
        .subtract(dateAggregationDefaultGap, dateAggregation)
        .startOf('month'),
    );
    if (comparisonEndDate)
      setComparisonStartDate(
        comparisonEndDate
          .subtract(dateAggregationDefaultGap, dateAggregation)
          .startOf('month'),
      );
  }, [dateAggregation]);

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          width: '60%',
          display: 'flex',
          justifyContent: 'center',
          gap: '80px',
          padding: '12px',
          marginBottom: '24px',
        }}
      >
        {TABS.map(tab => (
          <LBTButtonFilter
            key={tab.id}
            isSelected={tab.id === tabId}
            onClick={() => {
              setTabId(tab.id);
              setCurrentMetric(
                TABS.find(item => item.id === tab.id)?.metrics[0]!,
              );
            }}
          >
            {tab.label}
          </LBTButtonFilter>
        ))}
      </div>
      <div style={{ display: 'flex', gap: '16px', justifyContent: 'center' }}>
        <DateRange
          label="Range date"
          title="Range date"
          dateFormat={
            dateAggregation === DateAggregationEnum.month
              ? 'MMMM YYYY'
              : undefined
          }
          startDate={startDate}
          endDate={endDate}
          setDate={(start, end) => {
            setStartDate(start);
            setEndDate(end);
          }}
        />
        <DateRange
          label="Range date confronto"
          title="Range date confronto"
          dateFormat={
            dateAggregation === DateAggregationEnum.month
              ? 'MMMM YYYY'
              : undefined
          }
          startDate={comparisonStartDate}
          endDate={comparisonEndDate}
          setDate={(start, end) => {
            setComparisonStartDate(start);
            setComparisonEndDate(end);
          }}
        />
        <LBTSelect
          label="Metrica"
          value={currentMetric}
          items={currentTab.metrics.map(metric => ({
            id: metric,
            name: ANALYTIC_METRIC_LABEL[metric],
          }))}
          handleChange={value => setCurrentMetric(value)}
          required
        />
        <LBTSelect
          label="Aggregazione per periodo"
          value={dateAggregation}
          items={[
            {
              id: DateAggregationEnum.day,
              name: 'Giornaliero',
            },
            {
              id: DateAggregationEnum.week,
              name: 'Settimanale',
            },
            {
              id: DateAggregationEnum.month,
              name: 'Mensile',
            },
          ]}
          handleChange={value => setDateAggregation(value)}
          required
        />
        {currentUser.role === UserRole.ADMIN && (
          <LBTSelect
            id="company_id-select"
            name="company_id"
            label="Seleziona azienda"
            value={companyId}
            items={companies.map(item => ({
              id: item.id as number,
              name: `${item.name} - ${item.vat_number}`,
            }))}
            handleChange={value => setCompanyId(value)}
          />
        )}
      </div>
      {!!metricLoader ? (
        <>
          <LBTSpacer spacing={6} />
          <LBTProgressSpinner />
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            width: comparisonMetricData ? '100%' : '80%',
            justifyContent: comparisonMetricData ? 'space-between' : 'center',
            paddingTop: '32px',
          }}
        >
          <div style={{ width: comparisonMetricData ? '50%' : '100%' }}>
            <Chart
              metricData={metricData}
              borderColor={COLORS.getInstance().PRIMARY_MAIN}
              backgroundColor={COLORS.getInstance().PRIMARY_SUPERLIGHT}
              dateAggregation={dateAggregation}
            />
          </div>
          {comparisonMetricData && (
            <div style={{ width: comparisonMetricData ? '50%' : '100%' }}>
              <Chart
                metricData={comparisonMetricData}
                borderColor={COLORS.getInstance().TANGERINE_MAIN}
                backgroundColor={COLORS.getInstance().TANGERINE_SUPERLIGHT}
                dateAggregation={dateAggregation}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
