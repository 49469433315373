import { CallbackInterface } from 'recoil';
import {
  continueFlowLoaderState,
  continueFlowState,
  questionFlowLoaderState,
  questionFlowState,
  userAnswersLoaderState,
  userAnswersState,
} from '../state';
import { ContinueFlow, EvalAction, Id, UpdateVisibility } from '../types';
import { setSnackbarError } from '../common';
import {
  clearResponses,
  continueFlow,
  evalAction,
  getAnswers,
  initFlow,
  updateVisibility,
} from '../actions';

export const initFlowCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(questionFlowLoaderState, true);
    let res = undefined;
    try {
      res = await initFlow(params);
      set(questionFlowState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionFlowLoaderState, false);
    return res;
  };

export const continueFlowCallback =
  ({ set }: CallbackInterface) =>
  async (params: ContinueFlow) => {
    set(continueFlowLoaderState, true);
    let res = undefined;
    try {
      res = await continueFlow(params);
      set(continueFlowState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(continueFlowLoaderState, false);
    return res;
  };

export const evalActionCallback =
  ({ set }: CallbackInterface) =>
  async (params: EvalAction) => {
    set(questionFlowLoaderState, true);
    let res = undefined;
    try {
      res = await evalAction(params);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionFlowLoaderState, false);
    return res;
  };

export const updateVisibilityCallback =
  ({ set }: CallbackInterface) =>
  async (params: UpdateVisibility) => {
    let res = undefined;
    try {
      res = await updateVisibility(params);
      set(continueFlowState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    return res;
  };

export const getAnswersCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(userAnswersLoaderState, true);
    let res = undefined;
    try {
      res = await getAnswers(params);
      set(userAnswersState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(userAnswersLoaderState, false);
    return res;
  };

export const clearResponsesCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(questionFlowLoaderState, true);
    let res = undefined;
    try {
      res = await clearResponses(params);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionFlowLoaderState, false);
    return res;
  };
