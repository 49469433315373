import { authPost, PATH, authGetAll, authPut, authDelete } from '../../http';
import { Company, GetFilter, Id, Post, Put } from '../types';

export const postCompany = async (item: Post<Company>) => {
  return await authPost(`api/v1/${PATH.COMPANIES}/`, item);
};

export const getCompanyById = async ({ id }: Id) => {
  return await authGetAll(`api/v1/${PATH.COMPANIES}/${id}`);
};

export const getAllCompanies = async ({ skip, limit }: GetFilter) => {
  return await authGetAll(`api/v1/${PATH.COMPANIES}/all/`, {
    skip,
    limit,
  });
};

export const putCompany = async (item: Put<Company>) => {
  return await authPut(`api/v1/${PATH.COMPANIES}`, item);
};

export const deleteCompany = async ({ id }: Id) => {
  return await authDelete(`api/v1/${PATH.COMPANIES}/${id}`);
};
