import { authPost, PATH, authGetAll, authPut, authDelete } from '../../http';
import {
  Post,
  AttributeMeta,
  GetAttributesMetaByEntityType,
  Put,
  Id,
} from '../types';

export const postAttributeMeta = async (item: Post<AttributeMeta>) => {
  return await authPost(
    `api/v1/${PATH.QUESTIONARIES}/${PATH.USER_ATTRIBUTE_META}/`,
    item,
  );
};

export const getAttributeMetaById = async ({ id }: Id) => {
  return await authGetAll(
    `api/v1/${PATH.QUESTIONARIES}/${PATH.USER_ATTRIBUTE_META}/detail/${id}`,
  );
};

export const getAttributesMetaByEntityType = async ({
  entity_type,
}: GetAttributesMetaByEntityType) => {
  return await authGetAll(
    `api/v1/${PATH.QUESTIONARIES}/${PATH.USER_ATTRIBUTE_META}/${entity_type}`,
  );
};

export const putAttributeMeta = async (item: Put<AttributeMeta>) => {
  return await authPut(
    `api/v1/${PATH.QUESTIONARIES}/${PATH.USER_ATTRIBUTE_META}`,
    item,
  );
};

export const deleteAttributeMeta = async ({ id }: Id) => {
  return await authDelete(`api/v1/${PATH.ATTRIBUTES}/${id}`);
};
