import { useRecoilCallback, useSetRecoilState } from 'recoil';
import {
  getRegioniCallback,
  getProvinceCallback,
  currentCompanyConfigState,
  postUserRegisterCallback,
  currentUserState,
  User,
} from '../recoil';
import useCompanyConfig from './useCompanyConfig';
import { useState } from 'react';
import { getRealmFromUrl } from '../utils';

interface Returns {
  onStartup: () => Promise<void>;
  isCompletedStartup: boolean;
}

export default function useStartup(): Returns {
  const { setCompanyConfig } = useCompanyConfig();
  const [isCompletedStartup, setIscompletedStartup] = useState<boolean>(false);

  const getRegions = useRecoilCallback(getRegioniCallback, []);
  const getProvinces = useRecoilCallback(getProvinceCallback, []);
  const registerUser = useRecoilCallback(postUserRegisterCallback, []);
  const setCompanyConfigState = useSetRecoilState(currentCompanyConfigState);
  const setCurrentUser = useSetRecoilState(currentUserState);

  const setConfig = async () => {
    const name =
      getRealmFromUrl() ??
      process.env.REACT_APP_KEYCLOAK_REALM_NAME ??
      undefined;
    if (!name) return;
    setCurrentUser((user: User) => {
      return { ...user, company_name: name };
    });
    const companyConfig = await setCompanyConfig(name);
    if (!companyConfig) return;
    setCompanyConfigState({ ...companyConfig, realm: name });
  };
  const setUser = async () => {
    const res = await registerUser();
    if (!res || !res.data) return;
    setCurrentUser(res.data);
  };

  const onStartup = async () => {
    await setUser();
    await setConfig();
    await getRegions();
    await getProvinces();
    setIscompletedStartup(true);
  };

  return { onStartup, isCompletedStartup };
}
