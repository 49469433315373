export interface MetricData {
  date: string;
  value: number;
}

export interface MetricDimension {
  name?: string;
  data: MetricData[];
}

export interface Metric {
  metric: string;
  unit: string;
  company: string;
  start_date: string;
  end_date: string;
  dimensions: MetricDimension[];
}

export class MetricClass implements Metric {
  metric = '';
  unit = '';
  company = '';
  start_date = '';
  end_date = '';
  dimensions = [];
}

export interface GetMetric {
  metric: string;
  start_date: string;
  dimension?: string;
  end_date: string;
  company_id: number | null;
}
