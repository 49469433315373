export type Modal = {
  children: React.ReactElement;
  title: string;
  subtitle1?: string;
  subtitle2?: string;
};

export enum SnackBarSeverity {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
}

export interface SnackBar {
  show: boolean;
  severity: SnackBarSeverity;
  message: string;
  code: number;
  duration?: number;
  fields?: { [x: string]: string };
}

export class SnackBarClass implements SnackBar {
  show = false;
  severity = SnackBarSeverity.success;
  message = '';
  code = 0;
  duration = 5000;
  fields = {};
}
