import { Stack, StackProps } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from '@laborability/components';
import { useBreakpoint, useScrollColor } from '@laborability/commons';
import { useRef } from 'react';

type SectionProps = StackProps & {
  backgroundColor?: string;
  datatestid?: string;
  children?: React.ReactNode;
};

const StyledStack = styled(Stack)<SectionProps>(
  ({ backgroundColor, datatestid }) => ({
    backgroundColor,
    datatestid,
  }),
);

export default function Section({
  children,
  datatestid,
  backgroundColor = COLORS.getInstance().WHITE,
  ...props
}: SectionProps) {
  const { isDesktop } = useBreakpoint();
  const startRef = useRef<HTMLDivElement>(null);
  useScrollColor(startRef, isDesktop ? 100 : 64, backgroundColor);

  return (
    <StyledStack
      ref={startRef}
      datatestid={`lbt-section-${datatestid}`}
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      backgroundColor={backgroundColor}
      padding={'0px 16px'}
      {...props}
    >
      {children}
    </StyledStack>
  );
}
