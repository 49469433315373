import {
  COLORS,
  IconArrowLeftComponent,
  IconArrowRightComponent,
  IconsSize,
  IconsStyle,
  LBTButton,
} from '@laborability/components';
import { Pagination, PaginationRenderItemParams } from '@mui/material';
import React, { useEffect } from 'react';
import styled from 'styled-components';

const StyledBullet = styled.div<{ bgcolor?: string }>`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${props => props.bgcolor};
  margin-left: 8px;
  cursor: pointer;
`;

const LBTPagination: React.FC<{
  onPageChange: (value: number) => void;
  numberOfItems: number;
  width?: string;
  currentPage?: number;
  leftBtnDatatestid?: string;
  rightBtnDatestid?: string;
}> = ({
  onPageChange,
  numberOfItems,
  width = '100%',
  currentPage = 0,
  leftBtnDatatestid = '',
  rightBtnDatestid = '',
}) => {
  const [page, setPage] = React.useState(currentPage);
  const handleChange = (
    event: React.ChangeEvent<unknown> | undefined,
    value: number,
  ) => {
    setPage(value);
    onPageChange(value);
  };

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const IconRight: React.FC<{ lastPage: boolean }> = ({ lastPage }) => (
    <IconArrowRightComponent
      size={IconsSize.MEDIUM}
      color={
        lastPage
          ? COLORS.getInstance().DISABLED
          : COLORS.getInstance().PRIMARY_DARK
      }
      style={IconsStyle.FILLED}
      datatestid=""
    />
  );
  const IconLeft: React.FC<{ firstPage: boolean }> = ({ firstPage }) => (
    <IconArrowLeftComponent
      size={IconsSize.MEDIUM}
      color={
        firstPage
          ? COLORS.getInstance().DISABLED
          : COLORS.getInstance().PRIMARY_DARK
      }
      style={IconsStyle.FILLED}
    />
  );

  const CustomPaginationItem: React.FC<{
    item: PaginationRenderItemParams;
  }> = ({ item }) => {
    return (
      <>
        {item.type === 'previous' && (
          <LBTButton
            variant="invisible"
            onClick={() => page > 1 && handleChange(undefined, page - 1)}
            datatestid={leftBtnDatatestid}
          >
            <IconLeft firstPage={page === 1} />
          </LBTButton>
        )}
        {item.type === 'next' && (
          <LBTButton
            variant="invisible"
            onClick={() =>
              page < numberOfItems && handleChange(undefined, page + 1)
            }
            datatestid={rightBtnDatestid}
          >
            <IconRight lastPage={page === numberOfItems} />
          </LBTButton>
        )}
        {item.type === 'page' && (
          <StyledBullet
            bgcolor={
              item.page === page
                ? COLORS.getInstance().PRIMARY_DARK
                : COLORS.getInstance().PRIMARY_SUPERLIGHT
            }
            onClick={item.onClick}
          />
        )}
      </>
    );
  };

  return (
    <Pagination
      sx={{
        display: 'flex',
        width: width,
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '504px',
        height: '52px',
        '> ul': {
          width: width,
          position: 'relative',
          justifyContent: 'center',
        },
        '> ul > li:first-child': {
          position: 'absolute',
          left: 0,
        },
        '> ul > li:last-child': {
          position: 'absolute',
          right: 0,
        },
      }}
      count={numberOfItems}
      page={page}
      onChange={handleChange}
      variant="text"
      renderItem={item => <CustomPaginationItem item={item} />}
    />
  );
};

export default LBTPagination;
