import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import GridLayout from '../components/GridLayout';
import { useGrid } from '../hooks/useGrid';
import {
  Domain,
  deleteDomainCallback,
  domainsState,
  getDomainsByKeyCallback,
} from '@laborability/commons';
import { DomainValueForm } from '../components/Forms';
import { useEffect } from 'react';
import { appBarState } from '../recoil/states/appBar';
import { OpenInNew } from '@mui/icons-material';
import { Box } from '@mui/material';
import { APP_ROUTES } from '../Routes';
import { useNavigate, useParams } from 'react-router-dom';
import { LBTButton } from '@laborability/components';

export function DomainValuesPage() {
  const { domain, parent_id } = useParams();
  const navigate = useNavigate();
  const getAllDomainsByDomain = useRecoilCallback(getDomainsByKeyCallback, []);
  const deleteDomain = useRecoilCallback(deleteDomainCallback, []);
  const setAppBarName = useSetRecoilState(appBarState);

  const { rowData, handleFetchAll, handleDelete } = useGrid<Domain>({
    state: domainsState,
    onFetch: async () => {
      if (domain) return await getAllDomainsByDomain({ id: domain });
      return [];
    },
    onDelete: deleteDomain,
  });

  const columnDefs: ColDef[] = [
    {
      field: 'id',
      maxWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: false,
      headerName: 'Id',
    },
    {
      field: 'value',
      filter: 'agTextColumnFilter',
      flex: 2,
      headerName: 'Valore',
    },
    {
      field: 'domain',
      filter: 'agTextColumnFilter',
      flex: 2,
      headerName: 'Dominio',
    },
    {
      field: 'open',
      headerName: 'Apri',
      width: 100,
      cellRenderer: (data: ICellRendererParams) => {
        return (
          <Box
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(`/${APP_ROUTES.DOMAIN}/${data.data.id}`)}
          >
            <OpenInNew />
          </Box>
        );
      },
      pinned: 'right' as any,
    },
  ];

  useEffect(() => {
    setAppBarName(`Valori dominio - ${domain}`);
  }, []);

  return (
    <GridLayout<Domain>
      tableId="domain_value"
      pageName="Record"
      columnDefs={columnDefs}
      rowData={
        !parent_id
          ? rowData
          : rowData.filter(item => item.parent_id === Number(parent_id))
      }
      handleFetchAll={handleFetchAll}
      handleDelete={handleDelete}
      FormComponent={DomainValueForm}
      recordNameAccessor={item => item.value ?? ''}
      formExtraParams={{ domain_id: domain, parent_id }}
      gridFilters={
        <LBTButton
          variant="outlined"
          color="primary"
          onClick={() => navigate(-1)}
        >
          Indietro
        </LBTButton>
      }
    />
  );
}
