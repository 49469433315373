import { Box, Stack } from '@mui/material';
import {
  ColDef,
  GetRowIdParams,
  GridReadyEvent,
  ICellRendererParams,
  IDatasource,
  RowSelectedEvent,
} from 'ag-grid-community';
import { ReactElement, useState } from 'react';
import { DeleteAlert } from './DeleteAlert';
import { AgGridReact } from 'ag-grid-react';
import { Modal } from '@laborability/commons';
import { LBTModal } from '@laborability/components';
import { LBTButton } from '@laborability/components';
import { Delete, Edit } from '@mui/icons-material';

interface Props<T> {
  tableId: string;
  pageName: string;
  columnDefs: ColDef[];
  rowData?: T[];
  handleFetchAll: () => Promise<any>;
  handleDelete?: ({ id }: { id: number }) => Promise<boolean | null>;
  FormComponent: (params: {
    id?: any;
    onClose?: () => void;
    extraParams: { [x: string]: any };
  }) => ReactElement | null;
  recordNameAccessor?: (item: T) => string;
  gridFilters?: ReactElement;
  dataSource?: IDatasource;
  hasAddNew?: boolean;
  hasEdit?: boolean;
  tableHeight?: string;
  emptyResponse?: boolean;
  deleteWarning?: string;
  formExtraParams?: { [x: string]: any };
  // props per la grid
  [key: string]: any;
}

export default function GridLayout<T extends { id?: number | string }>({
  tableId,
  pageName,
  columnDefs,
  rowData,
  handleFetchAll,
  handleDelete,
  FormComponent,
  recordNameAccessor,
  dataSource,
  gridFilters,
  hasAddNew = true,
  hasEdit = true,
  tableHeight,
  formExtraParams,
  emptyResponse = false,
  deleteWarning,
  ...rest
}: Props<T>) {
  const hasDelete = !!handleDelete;
  const [modal, setModal] = useState<Modal | null>(null);
  const paginationStorage = `bo-grid_size-${tableId}`;
  const paginationPageSize = localStorage.getItem(paginationStorage);

  const callbackOnEdit = async (data: T) => {
    if (data && data.id) {
      setModal({
        children: (
          <FormComponent
            id={data.id}
            onClose={() => setModal(null)}
            extraParams={formExtraParams ?? {}}
          />
        ),
        title: `Modifica ${pageName}`,
      });
    }
  };

  const callbackOnDelete = async (data: T) => {
    if (handleDelete && data && data.id) {
      setModal({
        children: (
          <DeleteAlert
            onDelete={async (id: number) => {
              if (await handleDelete({ id })) {
                handleFetchAll();
                setModal(null);
              }
              if (emptyResponse) {
                handleFetchAll();
                setModal(null);
              }
            }}
            onClose={() => setModal(null)}
            entityId={data?.id as number}
            message={
              <b>
                Sicuro di voler cancellare: {recordNameAccessor?.(data)}?
                <br />
                {deleteWarning ?? 'Tutti i dati correlati verrano persi!'}
              </b>
            }
          />
        ),
        title: `Cancella ${pageName}`,
      });
    }
  };

  const editCol = {
    field: 'edit',
    headerName: 'Modifica',
    width: 100,
    cellRenderer: (data: ICellRendererParams) => (
      <Box
        style={{ cursor: 'pointer' }}
        onClick={() => callbackOnEdit(data.data)}
      >
        <Edit />
      </Box>
    ),
    pinned: 'right',
  } as ColDef;

  const deleteCol = {
    field: 'delete',
    headerName: 'Cancella',
    width: 100,
    cellRenderer: (data: ICellRendererParams) => (
      <Box
        style={{ cursor: 'pointer' }}
        onClick={() => callbackOnDelete(data.data)}
      >
        <Delete />
      </Box>
    ),
    pinned: 'right',
  } as ColDef;

  const handleGridReady = async (params: GridReadyEvent) => {
    params.api.showLoadingOverlay();
    const res = await handleFetchAll();
    if (!res || !res?.total) params.api.showNoRowsOverlay();
    else params.api.hideOverlay();
  };

  const onRowSelected = async (event: RowSelectedEvent) => {
    // if (event.node.isSelected()) {
  };

  const handleOpenNewForm = () => {
    setModal({
      children: (
        <FormComponent
          onClose={() => setModal(null)}
          extraParams={formExtraParams ?? {}}
        />
      ),
      title: `Registra ${pageName}`,
    });
  };

  return (
    <>
      <Stack
        justifyContent={gridFilters ? 'space-between' : 'flex-end'}
        flexDirection={'row'}
        width="100%"
        sx={{ marginBottom: '16px' }}
      >
        {gridFilters}
        {hasAddNew ? (
          <LBTButton
            variant="outlined"
            color="primary"
            onClick={handleOpenNewForm}
          >
            Registra {pageName}
          </LBTButton>
        ) : (
          <div />
        )}
      </Stack>
      <div
        className="ag-theme-quartz"
        style={{
          height: tableHeight ? tableHeight : 'calc(100vh - 170px)',
          width: '100%',
        }}
      >
        <AgGridReact
          rowData={dataSource || !rowData?.length ? [] : rowData}
          columnDefs={[
            ...columnDefs,
            ...(hasEdit ? [editCol] : []),
            ...(hasDelete ? [deleteCol] : []),
          ]}
          rowHeight={50}
          defaultColDef={{
            sortable: true,
            floatingFilter: true,
          }}
          animateRows={true}
          rowSelection="single"
          pagination={true}
          rowModelType={dataSource ? 'infinite' : 'clientSide'}
          datasource={dataSource}
          paginationPageSize={
            paginationPageSize ? Number(paginationPageSize) : 20
          }
          onPaginationChanged={params =>
            localStorage.setItem(
              paginationStorage,
              String(params.api.paginationGetPageSize()),
            )
          }
          rowBuffer={0}
          cacheOverflowSize={2}
          maxConcurrentDatasourceRequests={1}
          infiniteInitialRowCount={20}
          maxBlocksInCache={10}
          onGridReady={handleGridReady}
          onRowSelected={onRowSelected}
          getRowId={(params: GetRowIdParams) => params.data.id}
          overlayLoadingTemplate={
            '<span class="ag-overlay-loading-center">loading...</span>'
          }
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">Non ci sono righe</span>'
          }
          {...rest}
        />
      </div>
      {!!modal && (
        <LBTModal
          open={!!modal}
          onXClose={() => setModal(null)}
          {...modal}
          modalStyle={{
            width: 'fit-content',
            maxWidth: '1400px',
            minWidth: '680px',
          }}
          defaultWidth="680px"
          contentStyle={{
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
          }}
        />
      )}
    </>
  );
}
