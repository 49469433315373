import { Domain, DomainClass, DomainKey, DomainKeyClass } from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const domainState = atom<Domain>({
  key: getUUID(),
  default: new DomainClass(),
});

export const domainsState = atom<Domain[]>({
  key: getUUID(),
  default: [],
});

export const domainKeyState = atom<DomainKey>({
  key: getUUID(),
  default: new DomainKeyClass(),
});

export const domainKeysState = atom<DomainKey[]>({
  key: getUUID(),
  default: [],
});

export const domainLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const domainsLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const domainKeyLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const domainKeysLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
