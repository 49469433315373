import { CallbackInterface } from 'recoil';
import {
  DeleteMeasureRule,
  GetMeasureRule,
  Id,
  MeasureRule,
  Post,
  PostMeasureRule,
  Put,
} from '../types';
import {
  postMeasureRule,
  getAllMeasureRules,
  putMeasureRule,
  deleteMeasureRule,
} from '../actions';
import { setSnackbarError, setSnackbarSuccess } from '../common';
import {
  measureRuleLoaderState,
  measureRuleState,
  measureRulesLoaderState,
  measureRulesState,
} from '../state';

export const postMeasureRuleCallback =
  ({ set }: CallbackInterface) =>
  async (params: Post<PostMeasureRule>) => {
    set(measureRuleLoaderState, true);
    let res = undefined;
    try {
      res = await postMeasureRule(params);
      set(measureRuleState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(
          set,
          'Regola misura caricata con sucesso',
          res.status,
        );
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(measureRuleLoaderState, false);
    return res;
  };

export const getAllMeasureRulesCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(measureRulesLoaderState, true);
    let res = undefined;
    try {
      res = await getAllMeasureRules(params);
      set(measureRulesState, res?.data?.items ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(measureRulesLoaderState, false);
    return res;
  };

export const getMeasureRuleByIdCallback =
  ({ set }: CallbackInterface) =>
  async ({ measure_id, rule_id }: GetMeasureRule) => {
    set(measureRuleLoaderState, true);
    let res = undefined;
    try {
      res = await getAllMeasureRules({ id: measure_id });
      set(
        measureRuleState,
        res?.data?.items?.find((item: MeasureRule) => item.id === rule_id) ??
          {},
      );
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(measureRuleLoaderState, false);
    return res;
  };

export const putMeasureRuleCallback =
  ({ set }: CallbackInterface) =>
  async (params: Put<PostMeasureRule>) => {
    set(measureRuleLoaderState, true);
    let res = undefined;
    try {
      res = await putMeasureRule(params);
      set(measureRuleState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(
          set,
          'Regola misura modificata con sucesso',
          res.status,
        );
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(measureRuleLoaderState, false);
    return res;
  };

export const deleteMeasureRuleCallback =
  ({ set }: CallbackInterface) =>
  async (params: DeleteMeasureRule) => {
    set(measureRuleLoaderState, true);
    let res = undefined;
    try {
      res = await deleteMeasureRule(params);
      if (res && res.data)
        setSnackbarSuccess(
          set,
          'Regola misura cancellata con sucesso',
          res.status,
        );
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(measureRuleLoaderState, false);
    return res;
  };
