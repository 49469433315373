import {
  CompanyConfig,
  CompanyConfigClass,
  CurrentCompanyConfig,
} from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const currentCompanyConfigState = atom<CurrentCompanyConfig>({
  key: getUUID(),
  default: {},
});

export const companyConfigState = atom<CompanyConfig>({
  key: getUUID(),
  default: new CompanyConfigClass(),
});

export const companyConfigsState = atom<CompanyConfig[]>({
  key: getUUID(),
  default: [],
});

export const companyConfigLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const companyConfigsLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
