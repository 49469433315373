import { AnalyticMetricEnum } from '@laborability/commons';

export const ANALYTIC_METRIC_LABEL: { [x: string]: string } = {
  [AnalyticMetricEnum.activeUsers]: 'Utenti attivi',
  [AnalyticMetricEnum.totalUsers]: 'Utenti totali',
  [AnalyticMetricEnum.sessions]: 'Sessioni',
  [AnalyticMetricEnum.averageSessionDuration]: 'Durata media sessioni',
  [AnalyticMetricEnum.activeUsersPercentage]: 'Percentuale utenti attivi',
  [AnalyticMetricEnum.averageSessionDurationPerUser]:
    'Percentuale durata media sessioni',
  [AnalyticMetricEnum.recurringUsers]: 'Utenti ricorrenti',
  [AnalyticMetricEnum.frequencyVisitsAgain]: 'Frequenza visita',
  [AnalyticMetricEnum.newUsers]: 'Nuovi utenti',
  [AnalyticMetricEnum.sessionSourceMedium]: 'Fonte sessioni',
  [AnalyticMetricEnum.sessionDeviceCategory]: 'Dispositivo sessioni',
  [AnalyticMetricEnum.pagePerSession]: 'Pagine per sessione',
  [AnalyticMetricEnum.sessionsExcludingMeasureViews]:
    'Sessioni senza visualizzazioni di misure',
  [AnalyticMetricEnum.averageQuestionaryCompletionPercentage]:
    'Percentuale completamento questionario',
  [AnalyticMetricEnum.outlinkClicks]: 'Outlink da contenuto',
  [AnalyticMetricEnum.mostPopularCategories]: 'Categorie più popolari',
  [AnalyticMetricEnum.measureMaxUsersEligibility]: 'Misure più elette',
  [AnalyticMetricEnum.measuresWithNoEligibility]: 'Misure non elette',
  [AnalyticMetricEnum.averageMeasuresPerEligibleUser]:
    'Media misure eleggibili per utente',
};

export enum dsadsadsa {
  totalUsers = 'totalUsers',
  recurringUsers = 'recurringUsers',
  mostViewed = 'mostViewed',
  userEngagementDuration = 'userEngagementDuration',
}

export const TAB_PANORAMICA_METRICS = [
  AnalyticMetricEnum.activeUsers,
  AnalyticMetricEnum.activeUsersPercentage,
  AnalyticMetricEnum.sessions,
  AnalyticMetricEnum.averageSessionDuration,
  //AnalyticMetricEnum.sessionSourceMedium,
  //AnalyticMetricEnum.sessionDeviceCategory,
];
export const TAB_UTENTI_METRICS = [
  AnalyticMetricEnum.pagePerSession,
  AnalyticMetricEnum.averageSessionDurationPerUser,
  AnalyticMetricEnum.newUsers,
  AnalyticMetricEnum.sessionsExcludingMeasureViews,
  AnalyticMetricEnum.averageQuestionaryCompletionPercentage,
  //AnalyticMetricEnum.frequencyVisitsAgain,
];
export const TAB_CONTENUTO_METRICS = [
  //AnalyticMetricEnum.outlinkClicks,
  //AnalyticMetricEnum.mostPopularCategories,
  AnalyticMetricEnum.measureMaxUsersEligibility,
  AnalyticMetricEnum.measuresWithNoEligibility,
  //AnalyticMetricEnum.averageMeasuresPerEligibleUser,
];

export const TABS = [
  {
    id: 1,
    label: 'Panoramica',
    metrics: TAB_PANORAMICA_METRICS,
  },
  {
    id: 2,
    label: 'Utenti',
    metrics: TAB_UTENTI_METRICS,
  },
  {
    id: 3,
    label: 'Contenuto',
    metrics: TAB_CONTENUTO_METRICS,
  },
];

export enum DateAggregationEnum {
  day = 'day',
  week = 'week',
  month = 'month',
}
