import { Grid } from '@mui/material';
import {
  MeasureRuleClass,
  getAllMeasureRulesCallback,
  getMeasureRuleByIdCallback,
  measureRuleLoaderState,
  measureRuleState,
  measureRulesState,
  postMeasureRuleCallback,
  putMeasureRuleCallback,
  removeNullishOrEmptyFields,
} from '@laborability/commons';
import ModalForm from '../ModalForm';
import { LBTSelect, LBTTextField } from '@laborability/components';
import QueryBuilderForm from '../QueryBuilder';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

interface Props {
  id?: number;
  onClose?: () => void;
}

export function MeasureRuleForm({ id, onClose }: Props) {
  const { id: measure_id } = useParams();
  const postMeasureRule = useRecoilCallback(postMeasureRuleCallback, []);
  const putMeasureRule = useRecoilCallback(putMeasureRuleCallback, []);
  const getAllMeasureRules = useRecoilCallback(getAllMeasureRulesCallback, []);
  const getMeasureRule = useRecoilCallback(getMeasureRuleByIdCallback, []);
  const [current, setCurrent] = useRecoilState(measureRuleState);
  const allRules = useRecoilValue(measureRulesState);

  const ruleLoader = useRecoilValue(measureRuleLoaderState);
  const isLoading = ruleLoader;

  const resetFormState = () => {
    setCurrent({
      ...new MeasureRuleClass(),
      execution_order: allRules.length + 1,
      schema: { combinator: 'and', not: false, rules: [] },
    });
  };

  useEffect(() => {
    if (id && measure_id) getMeasureRule({ measure_id, rule_id: id });
    else resetFormState();
  }, [id]);

  if (!measure_id) return null;

  return (
    <ModalForm
      defaultValues={{
        ...new MeasureRuleClass(),
        execution_order: allRules.length + 1,
        schema: { combinator: 'and', not: false, rules: [] },
      }}
      isLoading={isLoading}
      currentValues={current}
      validation={values => {
        return {
          ...(values.name === '' ? { name: 'Obbligatorio' } : {}),
          ...(values.name?.length! > 255
            ? { name: 'Lunghezza massima 255 caratteri' }
            : {}),
        };
      }}
      handleSubmit={async values => {
        if (values.id) {
          const res = await putMeasureRule({
            id: values.id,
            ...removeNullishOrEmptyFields(values),
            measure_id,
          });
          if (res?.data) onClose?.();
          return;
        }
        const res = await postMeasureRule({
          ...removeNullishOrEmptyFields(values),
          measure_id,
        });
        if (res?.data) onClose?.();
        return;
      }}
      resetGridState={() => getAllMeasureRules({ id: measure_id })}
      resetFormState={resetFormState}
    >
      {({ values, errors, handleBlur, setFieldValue }) => (
        <>
          <Grid item mobile={12}>
            <LBTTextField
              label="Nome"
              type="text"
              name="name"
              onChange={value => setFieldValue('name', value)}
              onBlur={handleBlur}
              value={values.name}
              required
              error={!!errors?.name}
              helperText={errors?.name}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTSelect
              id="execution_order-select"
              name="execution_order"
              value={values.execution_order}
              label="Ordine di esecuzione"
              handleChange={e => setFieldValue('execution_order', e)}
              onBlur={handleBlur}
              fullWidth
              required
              items={Array.from(
                Array(allRules.length + (values.id ? 0 : 1)).keys(),
              ).map(i => ({ id: i + 1, name: i + 1 }))}
              error={!!errors?.execution_order}
              helperText={errors?.execution_order}
            />
          </Grid>
          <Grid item mobile={12}>
            <QueryBuilderForm
              query={values.schema as any}
              errors={errors}
              onQueryChange={value => setFieldValue('schema', value, false)}
            />
          </Grid>
        </>
      )}
    </ModalForm>
  );
}
