export type Entity = {
  id?: number;
  name?: string;
};

export class EntityClass implements Entity {
  id = 0;
  name = '';
}

export interface GetEntityByType {
  entity_type: string;
}
