import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { appBarState } from '../recoil/states/appBar';

export function NoPage404() {
  const setAppBarName = useSetRecoilState(appBarState);

  useEffect(() => {
    setAppBarName('Pagina non trovata');
  }, []);

  return <div>NoPage404</div>;
}
