import { RecoilState, atom } from 'recoil';
import { snackBarState } from './state';
import { SnackBarSeverity } from './types';
import { getUUID } from '../utils';

export function setSnackbarError(
  set: <T>(
    recoilVal: RecoilState<T>,
    valOrUpdater: ((currVal: T) => T) | T,
  ) => void,
  error: any,
) {
  const data = error?.response?.data;
  const firstError = data?.errors?.[0];
  const errorFields: { [x: string]: string } = (
    data?.errors as Array<any>
  )?.reduce(
    (prev, current) => ({
      ...prev,
      [current?.loc]: current.msg,
    }),
    {},
  );
  const message = data?.detail
    ? `Attenzione, errore ${error?.response?.status} ${firstError?.type ?? 'generic error'}: ${firstError?.msg ?? 'Errore generico'}`
    : data?.message ?? 'Errore generico';
  set(snackBarState, {
    show: true,
    message,
    severity: SnackBarSeverity.error,
    duration: 5000,
    code: error?.response?.status,
    fields: errorFields,
  });
}

export function setSnackbarSuccess(
  set: <T>(
    recoilVal: RecoilState<T>,
    valOrUpdater: ((currVal: T) => T) | T,
  ) => void,
  message: string,
  code: number,
) {
  set(snackBarState, {
    show: true,
    message,
    severity: SnackBarSeverity.success,
    duration: 5000,
    code,
  });
}

export const navMenuState = atom<string>({
  key: getUUID(),
  default: '#FFFFFF',
});

export const swipeableDrawerState = atom<{
  open: boolean;
  title: string;
  description: string;
  component: React.ReactNode | null;
}>({
  key: getUUID(),
  default: {
    open: false,
    title: '',
    description: '',
    component: null,
  },
});
