import { Chip, ChipProps } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import LBTLabel from '../Label';

const StyledChip = styled(Chip)<
  ChipProps & { datatestid: string; 'data-track': string }
>(({ datatestid, ...props }) => ({
  datatestid,
  'data-track': props['data-track'],

  '&.MuiChip-root': {
    padding: '3px 8px',
  },

  '.MuiChip-label': {
    padding: '0px',
  },

  '&.MuiChip-root > :last-child': {
    marginLeft: '4px',
    marginRight: '0px',
  },
}));

export type LBTChipProps = ChipProps & { datatestid?: string };

const LBTChip: React.FC<LBTChipProps> = ({
  size,
  icon,
  deleteIcon,
  label,
  onDelete,
  datatestid = '',
  ...props
}: LBTChipProps) => {
  return (
    <StyledChip
      datatestid={`lbt-chip-${datatestid}`}
      data-track={datatestid}
      size={size}
      icon={icon}
      deleteIcon={deleteIcon}
      onDelete={onDelete}
      label={
        <LBTLabel variant="chipLabel" textAlign="center">
          {label}
        </LBTLabel>
      }
      {...props}
    />
  );
};

export default LBTChip;
