import { authPost, PATH, authGetAll, authPut, authDelete } from '../../http';
import {
  AdvancedSearchMisura,
  GetAllMisura,
  GetMisuraByCategory,
  GetMisuraByTag,
  GetMisuraMostPopular,
  Id,
  Misura,
  Post,
  Put,
  SearchMisura,
} from '../types';

export const postMisura = async (item: Post<Misura>) => {
  return await authPost(`api/v1/${PATH.MISURE}/`, item);
};

export const searchMisura = async ({
  query,
  published,
  skip,
  limit,
}: SearchMisura) => {
  return await authGetAll(`api/v1/${PATH.MISURE}/search`, {
    query,
    skip,
    limit,
    published,
  });
};

export const advancedSearchMisura = async ({
  category_id,
  published,
  news,
  upcoming,
  expiring_soon,
  for_all_incomes,
  question_online_or_phone,
  skip,
  limit,
  region_id,
  benefit_type_id,
  national,
  regional,
  sub_categories,
}: AdvancedSearchMisura) => {
  return await authPost(
    `api/v1/${PATH.MISURE}/advanced_search/${category_id}`,
    {
      skip,
      limit,
      published,
      news,
      upcoming,
      expiring_soon,
      for_all_incomes,
      question_online_or_phone,
      region_id,
      benefit_type_id,
      national,
      regional,
      sub_categories,
    },
  );
};

export const getMisuraById = async ({ id }: Id) => {
  return await authGetAll(`api/v1/${PATH.MISURE}/${PATH.PUBLIC}/${id}`);
};

export const getMisureByTag = async ({
  tag_id,
  published,
  skip,
  limit,
}: GetMisuraByTag) => {
  return await authGetAll(`api/v1/${PATH.MISURE}/list-by-tag/`, {
    tag_id,
    skip,
    limit,
    published,
  });
};

export const getMisureMostPopular = async ({
  limit,
  published,
}: GetMisuraMostPopular) => {
  return await authGetAll(
    `api/v1/${PATH.MISURE}/${PATH.PUBLIC}/most-popular/`,
    { limit, published },
  );
};

export const getMisureByCategoryId = async ({
  id,
  published,
  skip,
  limit,
}: GetMisuraByCategory) => {
  return await authGetAll(`api/v1/${PATH.MISURE}/list-by-category/${id}`, {
    skip,
    limit,
    published,
  });
};

export const getAllMisure = async ({
  published,
  skip,
  limit,
}: GetAllMisura) => {
  return await authGetAll(`api/v1/${PATH.MISURE}/${PATH.PUBLIC}/`, {
    skip,
    limit,
    published,
  });
};

export const putMisura = async (item: Put<Misura>) => {
  return await authPut(`api/v1/${PATH.MISURE}`, item);
};

export const putStatusMisura = async () => {
  return await authPut(
    `api/v1/${PATH.MISURE}/${PATH.PUBLIC}/${PATH.STATUS_MEASURE}/`,
    undefined,
  );
};

export const deleteMisura = async ({ id }: Id) => {
  return await authDelete(`api/v1/${PATH.MISURE}/${id}`);
};
