import { CallbackInterface } from 'recoil';
import { setSnackbarSuccess, setSnackbarError } from '../common';
import { Id, Post, PostQuestionPage, Put } from '../types';
import {
  questionPageLoaderState,
  questionPageState,
  questionPagesLoaderState,
  questionPagesState,
} from '../state';
import {
  postQuestionPage,
  getAllQuestionPage,
  putQuestionPage,
  deleteQuestionPage,
  getQuestionPageById,
} from '../actions';

export const postQuestionPageCallback =
  ({ set }: CallbackInterface) =>
  async (params: Post<PostQuestionPage>) => {
    set(questionPageLoaderState, true);
    let res = undefined;
    try {
      res = await postQuestionPage(params);
      set(questionPageState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Pagina creata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionPageLoaderState, false);
    return res;
  };

export const getQuestionPageByIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(questionPageLoaderState, true);
    let res = undefined;
    try {
      res = await getQuestionPageById(params);
      set(questionPageState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionPageLoaderState, false);
    return res;
  };

export const getAllQuestionPageCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(questionPagesLoaderState, true);
    let res = undefined;
    try {
      res = await getAllQuestionPage(params);
      set(questionPagesState, res?.data?.items ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionPagesLoaderState, false);
    return res;
  };

export const putQuestionPageCallback =
  ({ set }: CallbackInterface) =>
  async (params: Put<PostQuestionPage>) => {
    set(questionPageLoaderState, true);
    let res = undefined;
    try {
      res = await putQuestionPage(params);
      set(questionPageState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Pagina modificata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionPageLoaderState, false);
    return res;
  };

export const deleteQuestionPageCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(questionPageLoaderState, true);
    let res = undefined;
    try {
      res = await deleteQuestionPage(params);
      if (res && res.data)
        setSnackbarSuccess(set, 'Pagina cancellata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(questionPageLoaderState, false);
    return res;
  };
