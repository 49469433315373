export type Benefit = {
  id?: number;
  name?: string;
  description?: string;
};

export class BenefitClass implements Benefit {
  id = 0;
  name = '';
  description = '';
}
