import { IconsSize, IconsStyle } from '../../enums';

const IconArrowDownComponent = ({
  size,
  style,
  color,
}: {
  size: IconsSize;
  style: IconsStyle;
  color: string;
}) => {
  return style === IconsStyle.OUTLINE ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.26333 7.04988C2.49856 6.93343 2.78459 7.02783 2.9022 7.26072C4.99981 11.4142 7.07193 13.6713 10.8277 15.7553C11.5564 16.1577 12.4439 16.1577 13.1725 15.7551C16.9282 13.6696 19.0003 11.414 21.0978 7.26072C21.2154 7.02783 21.5014 6.93343 21.7367 7.04988C21.9719 7.16632 22.0672 7.44951 21.9496 7.6824C19.7774 11.9836 17.5798 14.389 13.6377 16.5779L13.6368 16.5784C12.6196 17.1405 11.3804 17.1405 10.3632 16.5784L10.3625 16.578C6.42012 14.3906 4.22251 11.9835 2.05038 7.6824C1.93276 7.44951 2.02811 7.16632 2.26333 7.04988Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.20412 9.32873C1.51873 8.07975 2.67838 6.73639 3.91759 7.0447C5.94164 7.54807 9.3487 8.27795 12 8.27795C14.6528 8.27795 18.0584 7.54807 20.0824 7.0447C21.3216 6.73639 22.4813 8.07975 21.7959 9.32873C19.7229 13.1071 17.4464 15.4179 13.7257 17.5399C12.6533 18.1534 11.3483 18.1534 10.2743 17.5399C6.55359 15.4195 4.27865 13.1087 2.20412 9.3303V9.32873Z"
        fill={color}
      />
    </svg>
  );
};

export default IconArrowDownComponent;
