import { authGetAll, PATH } from '../../http';
import { Id } from '../types';

export const getRegioni = async () => {
  return await authGetAll(
    `api/v1/${PATH.COMUNI}/${PATH.PUBLIC}/${PATH.REGIONI}`,
  );
};

export const getProvince = async () => {
  return await authGetAll(
    `api/v1/${PATH.COMUNI}/${PATH.PUBLIC}/${PATH.PROVINCE}`,
  );
};

export const getProvinceByRegionId = async ({ id }: Id) => {
  return await authGetAll(
    `api/v1/${PATH.COMUNI}/${PATH.PUBLIC}/${PATH.PROVINCE}/${id}`,
  );
};

export const getComuni = async () => {
  return await authGetAll(
    `api/v1/${PATH.COMUNI}/${PATH.PUBLIC}/${PATH.COMUNI}/`,
  );
};

export const getComuniByProvinceId = async ({ id }: Id) => {
  return await authGetAll(
    `api/v1/${PATH.COMUNI}/${PATH.PUBLIC}/${PATH.COMUNI}/${PATH.PROVINCIA}/${id}`,
  );
};
