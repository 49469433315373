import { IconsSize, IconsStyle } from '../../enums';
import StyledIcon from './StyledIcon';

const IconCrossComponent = ({
  datatestid = '',
  size,
  style,
  color,
}: {
  datatestid?: string;
  size: IconsSize;
  style: IconsStyle;
  color: string;
}) => {
  return style === IconsStyle.OUTLINE ? (
    <StyledIcon
      datatestid={`lbt-icon-${datatestid}`}
      data-track={datatestid}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L11.2941 12.0012L2.14645 21.1488C1.95118 21.3441 1.95118 21.6607 2.14645 21.8559C2.34171 22.0512 2.65829 22.0512 2.85355 21.8559L12.0012 12.7083L21.1488 21.8559C21.3441 22.0512 21.6607 22.0512 21.8559 21.8559C22.0512 21.6607 22.0512 21.3441 21.8559 21.1488L12.7083 12.0012L21.8559 2.85355C22.0512 2.65829 22.0512 2.34171 21.8559 2.14645C21.6607 1.95118 21.3441 1.95118 21.1488 2.14645L12.0012 11.2941L2.85355 2.14645Z"
        fill={color}
      />
    </StyledIcon>
  ) : (
    <StyledIcon
      datatestid={`lbt-icon-${datatestid}`}
      data-track={datatestid}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5473 21.549C19.8167 21.8091 20.1775 21.9531 20.552 21.9498C20.9265 21.9466 21.2847 21.7964 21.5495 21.5316C21.8144 21.2667 21.9646 20.9085 21.9678 20.534C21.9711 20.1595 21.8271 19.7987 21.5669 19.5293L14.0283 11.9907L21.5812 4.43914C21.8492 4.17132 21.9999 3.808 22 3.42911C22.0001 3.05022 21.8497 2.6868 21.5819 2.41879C21.3141 2.15078 20.9508 2.00013 20.5719 2C20.193 1.99987 19.8296 2.15025 19.5616 2.41807L12.0086 9.97101L4.44141 2.40379C4.17202 2.14361 3.81123 1.99964 3.43673 2.0029C3.06223 2.00615 2.70399 2.15637 2.43916 2.42119C2.17434 2.68601 2.02413 3.04425 2.02087 3.41875C2.01762 3.79325 2.16159 4.15405 2.42177 4.42343L9.98756 11.9907L2.43605 19.5436C2.29963 19.6754 2.19082 19.833 2.11596 20.0072C2.0411 20.1815 2.0017 20.3689 2.00005 20.5586C1.99841 20.7482 2.03454 20.9363 2.10636 21.1118C2.17818 21.2874 2.28424 21.4468 2.41834 21.5809C2.55245 21.715 2.71193 21.8211 2.88746 21.8929C3.063 21.9647 3.25108 22.0009 3.44073 21.9992C3.63038 21.9976 3.8178 21.9582 3.99206 21.8833C4.16632 21.8085 4.32393 21.6997 4.45569 21.5632L12.0086 14.0117L19.5473 21.5504V21.549Z"
        fill={color}
      />
    </StyledIcon>
  );
};

export default IconCrossComponent;
