import { authPost, PATH, authGetAll, authPut, authDelete } from '../../http';
import { Category, GetFilter, Id, Post, Put } from '../types';

export const postCategory = async (item: Post<Category>) => {
  return await authPost(`api/v1/${PATH.MISURE}/${PATH.CATEGORIES}/`, item);
};

export const getCategoryById = async ({ id }: Id) => {
  return await authGetAll(
    `api/v1/${PATH.MISURE}/${PATH.PUBLIC}/${PATH.CATEGORIES}/${id}`,
  );
};

export const getCategoriesHomePage = async ({ skip, limit }: GetFilter) => {
  return await authGetAll(
    `api/v1/${PATH.MISURE}/${PATH.PUBLIC}/categories-hp/`,
    { skip, limit },
  );
};

export const getAllCategories = async ({ skip, limit }: GetFilter) => {
  return await authGetAll(
    `api/v1/${PATH.MISURE}/${PATH.PUBLIC}/${PATH.CATEGORIES}/`,
    { skip, limit },
  );
};

export const getSubCategories = async ({ id, skip, limit }: Id & GetFilter) => {
  return await authGetAll(
    `api/v1/${PATH.MISURE}/${PATH.PUBLIC}/${PATH.SUB_CATEGORIES}/${id}`,
    { skip, limit },
  );
};

export const putCategory = async (item: Put<Category>) => {
  return await authPut(`api/v1/${PATH.MISURE}/${PATH.CATEGORIES}`, item);
};

export const deleteCategory = async ({ id }: Id) => {
  return await authDelete(`api/v1/${PATH.MISURE}/${PATH.CATEGORIES}/${id}`);
};
