import { Company, CompanyClass } from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const companyState = atom<Company>({
  key: getUUID(),
  default: new CompanyClass(),
});

export const companiesState = atom<Company[]>({
  key: getUUID(),
  default: [],
});

export const companyLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const companiesLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
