import styled from 'styled-components';
import { Stack, SwitchProps, Switch } from '@mui/material';
import LBTListItem from '../List';

const StyledSwitch = styled(Switch)<
  SwitchProps & {
    datatestid?: string;
    'data-track': string;
  }
>(({ theme, datatestid, ...props }) => ({
  datatestid,
  'data-track': props['data-track'],
}));

export type LBTSwitchProps = Omit<SwitchProps, 'onChange'> & {
  label?: string;
  description?: string;
  direction?: 'row' | 'row-reverse';
  datatestid?: string;
  onChange: (val?: boolean) => void;
  hasFullWidth?: boolean;
};

export default function LBTSwitch({
  label,
  description,
  direction = 'row',
  datatestid = '',
  onChange,
  hasFullWidth,
  ...props
}: LBTSwitchProps) {
  return (
    <Stack
      direction={direction}
      justifyContent="space-between"
      alignItems="center"
      sx={hasFullWidth ? { width: '100%' } : {}}
    >
      <StyledSwitch
        datatestid={`lbt-switch-${datatestid}`}
        data-track={datatestid}
        onChange={e => onChange(e.target.checked)}
        {...props}
      />
      {label && (
        <LBTListItem
          paddingLeft={false}
          title={label}
          description={description}
          titleComponent="label"
          descrptionComponent="label"
        />
      )}
    </Stack>
  );
}
