import Button, { ButtonProps } from '@mui/material/Button';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils';
import { TypographyProps } from '@mui/material';
import LBTLabel from '../Label';

export type LBTButtonFilterProps = ButtonProps & {
  onClick: () => void;
  isSelected: boolean;
  datatestid?: string;
  textProps?: TypographyProps;
};

const StyledButtonFilter = styled(Button)<
  ButtonProps & {
    datatestid: string;
    'data-track': string;
    isSelected: boolean;
  }
>(({ datatestid, isSelected = false, color, ...props }) => {
  return {
    datatestid,
    'data-track': props['data-track'],

    '&.MuiButton-root': {
      backgroundColor: isSelected
        ? COLORS.getInstance().PRIMARY_DARK
        : COLORS.getInstance().WHITE,
      borderColor: COLORS.getInstance().PRIMARY_DARK,
      borderWidth: '1px',

      '.MuiTypography-root': {
        color: isSelected
          ? COLORS.getInstance().PRIMARY_IPERLIGHT
          : COLORS.getInstance().PRIMARY_DARK,
      },
    },

    '& .MuiButton-startIcon > svg:first-child': {
      filter: props.disabled ? 'contrast(0%)' : 'contrast(100%)',
    },
    '& .MuiButton-endIcon > svg:last-child': {
      filter: props.disabled ? 'contrast(0%)' : 'contrast(100%)',
    },
  };
});

const LBTButtonFilter: React.FC<LBTButtonFilterProps> = ({
  datatestid = '',
  children,
  textProps,
  size,
  ...props
}: LBTButtonFilterProps) => {
  const getVariant = () => {
    if (size === 'small') return 'buttonSmall';
    if (size === 'medium') return 'buttonMedium';
    return 'buttonLarge';
  };

  return (
    <StyledButtonFilter
      datatestid={`lbt-button-filter-${datatestid}`}
      data-track={datatestid}
      variant="outlined"
      color="primary"
      size={size}
      {...props}
    >
      <LBTLabel
        datatestid={`lbt-button-filter-label-${datatestid}`}
        data-track={datatestid}
        {...textProps}
        variant={getVariant()}
      >
        {children}
      </LBTLabel>
    </StyledButtonFilter>
  );
};

export default LBTButtonFilter;
