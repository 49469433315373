import { CallbackInterface } from 'recoil';
import { setSnackbarError, setSnackbarSuccess } from '../common';
import { Benefit, GetFilter, Id, Post, Put } from '../types';
import {
  postBenefit,
  getAllBenefits,
  getBenefitById,
  putBenefit,
  deleteBenefit,
} from '../actions';
import {
  benefitLoaderState,
  benefitState,
  benefitsLoaderState,
  benefitsState,
} from '../state';

export const postBenefitCallback =
  ({ set }: CallbackInterface) =>
  async (params: Post<Benefit>) => {
    set(benefitLoaderState, true);
    let res = undefined;
    try {
      res = await postBenefit(params);
      set(benefitState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Beneficio caricato con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(benefitLoaderState, false);
    return res;
  };

export const getBenefitByIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(benefitLoaderState, true);
    let res = undefined;
    try {
      res = await getBenefitById(params);
      set(benefitState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(benefitLoaderState, false);
    return res;
  };

export const getAllBenefitsCallback =
  ({ set }: CallbackInterface) =>
  async (params: GetFilter = {}) => {
    set(benefitsLoaderState, true);
    let res = undefined;
    try {
      res = await getAllBenefits(params);
      set(benefitsState, res?.data?.items ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(benefitsLoaderState, false);
    return res;
  };

export const putBenefitCallback =
  ({ set }: CallbackInterface) =>
  async (params: Put<Benefit>) => {
    set(benefitLoaderState, true);
    let res = undefined;
    try {
      res = await putBenefit(params);
      set(benefitState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Beneficio modificato con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(benefitLoaderState, false);
    return res;
  };

export const deleteBenefitCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(benefitLoaderState, true);
    let res = undefined;
    try {
      res = await deleteBenefit(params);
      if (res && res.data)
        setSnackbarSuccess(set, 'Beneficio cancellato con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(benefitLoaderState, false);
    return res;
  };
