import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import GridLayout from '../components/GridLayout';
import { useGrid } from '../hooks/useGrid';
import {
  DomainKey,
  domainKeysState,
  getAllDomainKeysCallback,
} from '@laborability/commons';
import { DomainForm } from '../components/Forms';
import { useEffect } from 'react';
import { appBarState } from '../recoil/states/appBar';
import { OpenInNew } from '@mui/icons-material';
import { Box } from '@mui/material';
import { APP_ROUTES } from '../Routes';
import { useNavigate, useParams } from 'react-router-dom';
import { LBTButton } from '@laborability/components';

export function DomainsPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const getAllDomainKeys = useRecoilCallback(getAllDomainKeysCallback, []);
  const setAppBarName = useSetRecoilState(appBarState);

  const { rowData, handleFetchAll } = useGrid<DomainKey>({
    state: domainKeysState,
    onFetch: async () => await getAllDomainKeys({ domain_id: id }),
    onDelete: async () => {},
  });

  const columnDefs: ColDef[] = [
    {
      field: 'id',
      filter: 'agTextColumnFilter',
      flex: 2,
      headerName: 'Dominio',
    },
    {
      field: 'description',
      filter: 'agTextColumnFilter',
      flex: 2,
      headerName: 'Descrizione',
    },
    {
      field: 'open',
      headerName: 'Apri',
      width: 100,
      cellRenderer: (data: ICellRendererParams) => {
        if (data.data.value) return;
        return (
          <Box
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (id)
                return navigate(
                  `/${APP_ROUTES.DOMAIN_VALUES}/${data.data.id}/${id}`,
                );
              return navigate(`/${APP_ROUTES.DOMAIN_VALUES}/${data.data.id}`);
            }}
          >
            <OpenInNew />
          </Box>
        );
      },
      pinned: 'right' as any,
    },
  ];

  useEffect(() => {
    setAppBarName(`Pagina domini`);
  }, []);

  return (
    <GridLayout<DomainKey>
      tableId="domain_key"
      pageName="Record"
      columnDefs={columnDefs}
      rowData={rowData.map(item => ({
        id: item.domain,
        description: item.description,
        domain: item.domain,
      }))}
      handleFetchAll={handleFetchAll}
      FormComponent={DomainForm}
      recordNameAccessor={item => item.domain ?? ''}
      formExtraParams={{ parent_id: id, rowData }}
      gridFilters={
        id ? (
          <LBTButton
            variant="outlined"
            color="primary"
            onClick={() => navigate(-1)}
          >
            Indietro
          </LBTButton>
        ) : undefined
      }
    />
  );
}
