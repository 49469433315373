import { Grid } from '@mui/material';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import {
  categoriesState,
  getAllCategoriesCallback,
  postCategoryCallback,
  putCategoryCallback,
  removeNullishOrEmptyFields,
  getCategoryByIdCallback,
  categoryState,
  CategoryClass,
  categoriesLoaderState,
  categoryLoaderState,
} from '@laborability/commons';
import { useEffect } from 'react';
import ModalForm from '../ModalForm';
import { LBTSelect, LBTTextField } from '@laborability/components';
import ImageInput from '../ImageInput';

type Props = {
  id?: number;
};

export function CategoryForm({ id }: Props) {
  const postCategory = useRecoilCallback(postCategoryCallback, []);
  const putCategory = useRecoilCallback(putCategoryCallback, []);
  const getCategory = useRecoilCallback(getCategoryByIdCallback, []);
  const getAllCategories = useRecoilCallback(getAllCategoriesCallback, []);
  const [current, setCurrent] = useRecoilState(categoryState);
  const categories = useRecoilValue(categoriesState);

  const categoriesLoader = useRecoilValue(categoriesLoaderState);
  const categoryLoader = useRecoilValue(categoryLoaderState);
  const isLoading = categoriesLoader || categoryLoader;

  const resetFormState = () => {
    setCurrent(new CategoryClass());
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  useEffect(() => {
    if (id) getCategory({ id });
    else resetFormState();
  }, [id]);

  return (
    <ModalForm
      defaultValues={new CategoryClass()}
      isLoading={isLoading}
      currentValues={current}
      validation={values => {
        return {
          ...(values.name === ''
            ? { name: 'Il nome della categoria è obbligatorio' }
            : {}),
        };
      }}
      handleSubmit={async values => {
        if (values.id)
          return await putCategory({
            id: values.id,
            ...removeNullishOrEmptyFields(values),
          });
        return await postCategory(removeNullishOrEmptyFields(values));
      }}
      resetGridState={getAllCategories}
      resetFormState={resetFormState}
    >
      {({ values, errors, handleBlur, setFieldValue }) => (
        <>
          <Grid item mobile={12}>
            <LBTTextField
              label="Nome"
              type="text"
              name="name"
              onChange={value => setFieldValue('name', value)}
              onBlur={handleBlur}
              value={values.name}
              required
              error={!!errors?.name}
              helperText={errors?.name}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Descrizione"
              type="text"
              name="description"
              onChange={value => setFieldValue('description', value)}
              onBlur={handleBlur}
              value={values.description}
              error={!!errors?.description}
              helperText={errors?.description}
            />
          </Grid>
          <Grid item mobile={12}>
            <ImageInput
              value={values.icon as string}
              onChange={value => setFieldValue('icon', value)}
              error={!!errors?.icon}
              helperText={errors?.icon}
            />
          </Grid>
          <Grid item mobile={12}>
            <ImageInput
              value={values.image as string}
              onChange={value => setFieldValue('image', value)}
              error={!!errors?.image}
              helperText={errors?.image}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTSelect
              id="parent_id-select"
              name="parent_id"
              label="Categoria padre"
              value={values.parent_id}
              items={categories
                .filter(item => !item.parent_id)
                .map(item => ({
                  id: item.id as number,
                  name: item.name as string,
                }))}
              handleChange={e => {
                setFieldValue('parent_id', e);
              }}
              onBlur={handleBlur}
              error={!!errors?.parent_id}
              helperText={errors?.parent_id}
            />
          </Grid>
        </>
      )}
    </ModalForm>
  );
}
