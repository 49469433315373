import { useBreakpoint } from '@laborability/commons';

interface Props {
  spacing: number;
  isFixed?: boolean;
  direction?: 'vertical' | 'horizontal';
}

export default function LBTSpacer({
  spacing,
  isFixed = false,
  direction = 'vertical',
}: Props) {
  const { isDesktop } = useBreakpoint();
  const realSpacing = spacing + (isDesktop && !isFixed ? 2 : 0);

  return (
    <div
      style={{
        ...(direction === 'vertical'
          ? { height: `${realSpacing > 8 ? spacing : realSpacing * 8}px` }
          : { width: `${realSpacing > 8 ? spacing : realSpacing * 8}px` }),
      }}
    />
  );
}
