import { Grid } from '@mui/material';
import {
  ActionRuleClass,
  DestinationTypeEnum,
  Modal,
  QUESTIONARY_STEPS,
  actionRuleLoaderState,
  actionRuleState,
  actionRulesState,
  getActionRuleByIdCallback,
  getAllActionRuleCallback,
  postActionRuleCallback,
  putActionRuleCallback,
  questionFlowState,
  questionPagesLoaderState,
  questionPagesState,
  removeNullishOrEmptyFields,
} from '@laborability/commons';
import ModalForm from '../ModalForm';
import {
  LBTButton,
  LBTModal,
  LBTSelect,
  LBTTextField,
} from '@laborability/components';
import QueryBuilderForm from '../QueryBuilder';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SimpleQuestionaryPageForm } from './SimpleQuestionaryPage';

interface Props {
  id?: number;
  onClose?: () => void;
}

export function ActionRuleForm({ id, onClose }: Props) {
  const { id: action_id } = useParams();
  const postActionRule = useRecoilCallback(postActionRuleCallback, []);
  const putActionRule = useRecoilCallback(putActionRuleCallback, []);
  const getActionRule = useRecoilCallback(getActionRuleByIdCallback, []);
  const getAllActionRule = useRecoilCallback(getAllActionRuleCallback, []);
  const [current, setCurrent] = useRecoilState(actionRuleState);
  const [modal, setModal] = useState<Modal | null>(null);
  const questionPages = useRecoilValue(questionPagesState);
  const allRules = useRecoilValue(actionRulesState);
  const questionFlow = useRecoilValue(questionFlowState);

  const pagesLoader = useRecoilValue(questionPagesLoaderState);
  const ruleLoader = useRecoilValue(actionRuleLoaderState);
  const isLoading = ruleLoader || pagesLoader;

  const resetFormState = () => {
    setCurrent({
      ...new ActionRuleClass(),
      execution_order: allRules.length + 1,
      schema: { combinator: 'and', not: false, rules: [] },
      destination: DestinationTypeEnum.target_page,
    });
  };

  useEffect(() => {
    if (id && action_id) getActionRule({ action_id, id });
    else resetFormState();
  }, [id]);

  if (!action_id) return null;

  return (
    <>
      <ModalForm
        defaultValues={{
          ...new ActionRuleClass(),
          execution_order: allRules.length + 1,
          schema: { combinator: 'and', not: false, rules: [] },
          destination: DestinationTypeEnum.target_page,
        }}
        isLoading={isLoading}
        currentValues={current}
        validation={values => {
          return {
            ...(values.name === '' ? { name: 'Obbligatorio' } : {}),
            ...(values.name?.length! > 255
              ? { name: 'Lunghezza massima 255 caratteri' }
              : {}),
            ...(!values.target_page_id &&
            values.destination === DestinationTypeEnum.target_page
              ? { target_page_id: 'Obbligatorio' }
              : {}),
          };
        }}
        handleSubmit={async values => {
          if (values.id) {
            await putActionRule({
              id: values.id,
              ...removeNullishOrEmptyFields(values),
              action_id,
            });
            return;
          }
          await postActionRule({
            ...removeNullishOrEmptyFields(values),
            action_id,
          });
          return;
        }}
        resetGridState={() => getAllActionRule({ id: action_id })}
        resetFormState={resetFormState}
      >
        {({ values, errors, handleBlur, setFieldValue }) => (
          <>
            <Grid item mobile={12}>
              <LBTTextField
                label="Nome"
                type="text"
                name="name"
                onChange={value => setFieldValue('name', value)}
                onBlur={handleBlur}
                value={values.name}
                required
                error={!!errors?.name}
                helperText={errors?.name}
              />
            </Grid>
            <Grid item mobile={12}>
              <LBTSelect
                id="execution_order-select"
                name="execution_order"
                value={values.execution_order}
                label="Ordine di esecuzione"
                handleChange={e => setFieldValue('execution_order', e)}
                onBlur={handleBlur}
                fullWidth
                required
                items={Array.from(
                  Array(allRules.length + (values.id ? 0 : 1)).keys(),
                ).map(i => ({ id: i + 1, name: i + 1 }))}
                error={!!errors?.execution_order}
                helperText={errors?.execution_order}
              />
            </Grid>
            <Grid
              item
              mobile={values.destination === DestinationTypeEnum.menu ? 12 : 3}
            >
              <LBTSelect
                id="destination-select"
                name="destination"
                value={values.destination}
                label="Tipo destinazione"
                handleChange={e => {
                  setFieldValue('destination', e);
                  setFieldValue('target_page_id', undefined);
                  setFieldValue('destination_step', undefined);
                }}
                onBlur={handleBlur}
                fullWidth
                items={[
                  {
                    id: DestinationTypeEnum.target_page,
                    name: 'Pagina',
                  },
                  {
                    id: DestinationTypeEnum.next_step,
                    name: 'Step successivo',
                  },
                  {
                    id: DestinationTypeEnum.menu,
                    name: 'Menù',
                  },
                  {
                    id: DestinationTypeEnum.home_page,
                    name: 'Homepage',
                  },
                ]}
                required
                error={!!errors?.destination}
                helperText={errors?.destination}
                style={{ minWidth: 0 }}
              />
            </Grid>
            {values.destination === DestinationTypeEnum.target_page && (
              <Grid item mobile={8}>
                <LBTSelect
                  id="target_page_id-select"
                  name="target_page_id"
                  value={values.target_page_id}
                  label="Pagina di destinazione"
                  handleChange={e => setFieldValue('target_page_id', e)}
                  onBlur={handleBlur}
                  fullWidth
                  required
                  items={questionPages.map(item => ({
                    id: item.id as number,
                    name: item.name as string,
                  }))}
                  error={!!errors?.target_page_id}
                  helperText={errors?.target_page_id}
                />
              </Grid>
            )}
            {values.destination === DestinationTypeEnum.next_step && (
              <Grid item mobile={9}>
                <LBTSelect
                  id="destination_step-select"
                  name="destination_step"
                  value={values.destination_step}
                  label="Step di destinazione"
                  handleChange={e => setFieldValue('destination_step', e)}
                  onBlur={handleBlur}
                  fullWidth
                  required
                  items={Array.from(Array(QUESTIONARY_STEPS).keys()).map(i => ({
                    id: i + 1,
                    name: i + 1,
                  }))}
                  error={!!errors?.destination_step}
                  helperText={errors?.destination_step}
                />
              </Grid>
            )}
            {values.destination === DestinationTypeEnum.target_page && (
              <Grid
                item
                mobile={1}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                }}
              >
                <LBTButton
                  variant="outlined"
                  onClick={() =>
                    setModal({
                      children: (
                        <SimpleQuestionaryPageForm
                          flow_id={questionFlow.id as number}
                          onClose={(id: number) => {
                            setFieldValue('target_page_id', id);
                            setModal(null);
                          }}
                        />
                      ),
                      title: 'Crea pagina',
                    })
                  }
                >
                  +
                </LBTButton>
              </Grid>
            )}
            <Grid item mobile={12}>
              <QueryBuilderForm
                query={values.schema as any}
                errors={errors}
                onQueryChange={value => setFieldValue('schema', value, false)}
              />
            </Grid>
          </>
        )}
      </ModalForm>
      {modal && (
        <LBTModal
          open={!!modal}
          onXClose={() => setModal(null)}
          {...modal}
          modalStyle={{
            width: 'fit-content',
            maxWidth: '1000px',
            minWidth: '600px',
          }}
        />
      )}
    </>
  );
}
