import {
  LBTButton,
  LBTDatePicker,
  LBTModal,
  LBTTextField,
} from '@laborability/components';
import { Grid } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useState } from 'react';

interface Props {
  label: string;
  title: string;
  dateFormat?: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  setDate: (startDate: Dayjs, endDate: Dayjs) => void;
}

export default function DateRange({
  label,
  title,
  dateFormat = 'DD/MM/YYYY',
  startDate,
  endDate,
  setDate,
}: Props) {
  const [_startDate, _setStartDate] = useState<Dayjs | null>(startDate);
  const [_endDate, _setEndDate] = useState<Dayjs | null>(endDate);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <LBTTextField
        label={label}
        value={
          !startDate || !endDate
            ? '-'
            : `${startDate.format(dateFormat)}  -  ${endDate.format(dateFormat)}`
        }
        focused={false}
        contentEditable={false}
        sx={{ minWidth: '250px' }}
        onChange={() => {}}
        onClick={() => setIsOpen(true)}
      />
      <LBTModal
        title={title}
        open={isOpen}
        onXClose={() => setIsOpen(false)}
        defaultWidth="500px"
      >
        <Grid container gap={4} justifyContent="center" p={4}>
          <Grid item mobile={12}>
            <LBTDatePicker
              label="Da"
              value={_startDate}
              handleChange={value => {
                if (!value) return;
                if (!_endDate || value > _endDate) _setEndDate(value);
                _setStartDate(value);
              }}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTDatePicker
              label="A"
              value={_endDate}
              handleChange={value => {
                if (!value) return;
                if (!_startDate || value < _startDate) _setStartDate(value);
                _setEndDate(value);
              }}
            />
          </Grid>
          <Grid item mobile={3}>
            <LBTButton variant="outlined" onClick={() => setIsOpen(false)}>
              Annulla
            </LBTButton>
          </Grid>
          <Grid item mobile={3}>
            <LBTButton
              variant="contained"
              disabled={!_startDate || !_endDate}
              onClick={() => {
                setDate(_startDate!, _endDate!);
                setIsOpen(false);
              }}
            >
              Conferma
            </LBTButton>
          </Grid>
        </Grid>
      </LBTModal>
    </>
  );
}
