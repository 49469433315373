export interface AnalyticDate {
  date: string;
  value: number;
}

export interface AnalyticDimension {
  name: string;
  data: AnalyticDate[];
}

export interface AnalyticMetric {
  metric: AnalyticMetricEnum;
  unit: AnalyticMetricEnum;
  company: string;
  start_date: string;
  end_date: string;
  dimensions: AnalyticDimension[];
}

export enum AnalyticMetricEnum {
  activeUsers = 'activeUsers',
  totalUsers = 'totalUsers',
  sessions = 'sessions',
  sessionSourceMedium = 'sessionSourceMedium',
  sessionDeviceCategory = 'sessionDeviceCategory',
  averageSessionDuration = 'averageSessionDuration',
  activeUsersPercentage = 'activeUsersPercentage',
  averageSessionDurationPerUser = 'averageSessionDurationPerUser',
  recurringUsers = 'recurringUsers',
  frequencyVisitsAgain = 'frequencyVisitsAgain',
  newUsers = 'newUsers',
  measureMaxUsersEligibility = 'measureMaxUsersEligibility',
  measuresWithNoEligibility = 'measuresWithNoEligibility',
  averageMeasuresPerEligibleUser = 'averageMeasuresPerEligibleUser',
  averageQuestionaryCompletionPercentage = 'averageQuestionaryCompletionPercentage',
  pagePerSession = 'pagePerSession',
  sessionsExcludingMeasureViews = 'sessionsExcludingMeasureViews',
  mostPopularCategories = 'mostPopularCategories',
  outlinkClicks = 'outlinkClicks',
  mostViewed = 'mostViewed',
  userEngagementDuration = 'userEngagementDuration',
}
