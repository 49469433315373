import { Typography, TypographyProps, styled } from '@mui/material';
import React from 'react';
import { COLORS } from '../../utils';
import { useBreakpoint } from '@laborability/commons';

export type LBTTypographyStyles =
  | 'delaDisplay'
  | 'spGroteskSubtitle'
  | 'sourceSubtitle'
  | 'bodyText'
  | 'delaDisplay1'
  | 'delaDisplay2'
  | 'delaHeadline'
  | 'spGroteskTitle'
  | 'spGroteskSubtitle1'
  | 'spGroteskSubtitle2'
  | 'spGroteskSubtitle3'
  | 'sourceSubtitle1'
  | 'sourceSubtitle2'
  | 'sourceSubtitle3'
  | 'bodyBlockquote'
  | 'bodyText1'
  | 'bodyText2'
  | 'bodyLink'
  | 'sourceCaption'
  | 'smallCaps'
  | 'smallCapsBold'
  | 'alertTitle'
  | 'alertDescription'
  | 'buttonLarge'
  | 'buttonMedium'
  | 'buttonSmall'
  | 'chipLabel'
  | 'datePickerSelectedDate'
  | 'datePickerHighlighted'
  | 'datePickerSelectDate'
  | 'datePickerNumbers'
  | 'inputFormLabel'
  | 'inputHelper'
  | 'inputApiceForm'
  | 'listTitle'
  | 'listDescription'
  | 'miscTitleSpGrotesk'
  | 'miscTitleSource'
  | 'miscDescription'
  | 'progressSmallLabel'
  | 'datepickerTitle'
  | 'datepickerSubtitle';

export interface LBTLabelProps extends Omit<TypographyProps, 'variant'> {
  datatestid?: string;
  variant: LBTTypographyStyles;
}

export const StyledLabel = styled(Typography)<
  TypographyProps & { datatestid: string }
>(({ datatestid }) => ({
  datatestid: `lbt-label-${datatestid}`,
  'data-track': datatestid,
}));

const LBTLabel: React.FC<LBTLabelProps> = ({
  datatestid = 'lbt-label-',
  textAlign = 'center',
  color = COLORS.getInstance().BW_GREYS_JET_BLACK,
  children,
  variant,
  component = 'p',
  ...props
}: LBTLabelProps) => {
  const { isDesktop } = useBreakpoint();

  const getTextStyle = () => {
    if (variant === 'delaDisplay')
      return isDesktop ? 'delaDisplay1' : 'delaDisplay2';
    if (variant === 'spGroteskSubtitle')
      return isDesktop ? 'spGroteskSubtitle2' : 'spGroteskSubtitle3';
    if (variant === 'sourceSubtitle')
      return isDesktop ? 'sourceSubtitle2' : 'sourceSubtitle3';
    if (variant === 'bodyText') return isDesktop ? 'bodyText1' : 'bodyText2';
    return variant;
  };

  return (
    <StyledLabel
      datatestid={datatestid}
      textAlign={textAlign}
      color={color}
      children={children}
      variant={getTextStyle()}
      component={component}
      maxWidth={isDesktop ? '680px' : '358px'}
      display="block"
      {...props}
    />
  );
};

export default LBTLabel;
