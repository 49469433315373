import { ColDef } from 'ag-grid-community';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import GridLayout from '../components/GridLayout';
import { ContractForm } from '../components/Forms';
import { useGrid } from '../hooks/useGrid';
import {
  getAllContractsCallback,
  deleteContractCallback,
  Contract,
  contractsState,
} from '@laborability/commons';
import { useEffect } from 'react';
import { appBarState } from '../recoil/states/appBar';

const columnDefs: ColDef[] = [
  {
    field: 'id',
    maxWidth: 100,
    filter: 'agNumberColumnFilter',
    resizable: false,
    headerName: 'ID',
  },
  {
    field: 'name',
    flex: 2,
    filter: 'agTextColumnFilter',
    headerName: 'Contract',
  },
];

export function ContractsPage() {
  const getAllContracts = useRecoilCallback(getAllContractsCallback, []);
  const deleteContract = useRecoilCallback(deleteContractCallback, []);
  const setAppBarName = useSetRecoilState(appBarState);

  const { rowData, handleFetchAll, handleDelete } = useGrid<Contract>({
    state: contractsState,
    onFetch: getAllContracts,
    onDelete: deleteContract,
  });

  useEffect(() => {
    setAppBarName('Registrazione contratti');
  }, []);

  return (
    <GridLayout<Contract>
      tableId="contracts"
      pageName="Contract"
      columnDefs={columnDefs}
      rowData={rowData}
      handleFetchAll={handleFetchAll}
      handleDelete={handleDelete}
      FormComponent={ContractForm}
      recordNameAccessor={item => item.name ?? ''}
    />
  );
}
