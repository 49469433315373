import { CallbackInterface } from 'recoil';
import {
  CompanyConfig,
  GetFilter,
  GetCompanyConfigurationByRealmname,
  Id,
  Post,
  Put,
  PutCompanyConfiguration,
} from '../types';
import {
  postCompanyConfiguration,
  getAllCompanyConfiguration,
  deleteCompanyConfiguration,
  getCompanyConfiguration,
  getAllCompanyConfigurationByRealmName,
  putCompanyConfiguration,
} from '../actions';
import { setSnackbarError } from '../common';
import {
  companyConfigLoaderState,
  companyConfigState,
  companyConfigsLoaderState,
  companyConfigsState,
} from '../state';

export const postCompanyConfigurationCallback =
  ({ set }: CallbackInterface) =>
  async (params: Post<CompanyConfig>[]) => {
    set(companyConfigLoaderState, true);
    let res = undefined;
    try {
      res = await postCompanyConfiguration(params);
      set(companyConfigState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(companyConfigLoaderState, false);
    return res;
  };

export const getCompanyConfigurationCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(companyConfigLoaderState, true);
    let res = undefined;
    try {
      res = await getCompanyConfiguration(params);
      set(companyConfigState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(companyConfigLoaderState, false);
    return res;
  };

export const getAllCompanyConfigurationCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id & GetFilter) => {
    set(companyConfigsLoaderState, true);
    let res = undefined;
    try {
      res = await getAllCompanyConfiguration(params);
      set(companyConfigsState, res?.data ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(companyConfigsLoaderState, false);
    return res;
  };

export const getAllCompanyConfigurationByRealmNameCallback =
  ({ set }: CallbackInterface) =>
  async (params: GetCompanyConfigurationByRealmname) => {
    set(companyConfigsLoaderState, true);
    let res = undefined;
    try {
      res = await getAllCompanyConfigurationByRealmName(params);
    } catch (error: any) {}
    set(companyConfigsLoaderState, false);
    return res;
  };

export const putCompanyConfigurationCallback =
  ({ set }: CallbackInterface) =>
  async (params: Put<PutCompanyConfiguration>) => {
    set(companyConfigLoaderState, true);
    let res = undefined;
    try {
      res = await putCompanyConfiguration(params);
      set(companyConfigState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(companyConfigLoaderState, false);
    return res;
  };

export const deleteCompanyConfigurationCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(companyConfigLoaderState, true);
    let res = undefined;
    try {
      res = await deleteCompanyConfiguration(params);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(companyConfigLoaderState, false);
    return res;
  };
