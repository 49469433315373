import React from 'react';
import LBTLabel from '../Label';
import { styled } from '@mui/material';
import { COLORS } from '../../utils';
import { getUUID } from '@laborability/commons';
import { Button, ButtonProps } from '@mui/material';
import { IconLockComponent, IconUnlockComponent } from '../Icons';

const ID = getUUID();

export type LBTButtonIllustrativeProps = ButtonProps & {
  onClick: () => void;
  icon: React.ReactNode | string;
  label: string;
  subLabel?: string;
  locked?: boolean;
  iconBgColor?: string;
  datatestid?: string;
};

const StyledButtonIllustrative = styled(Button)<
  ButtonProps & {
    datatestid: string;
    'data-track': string;
    iconBgColor?: string;
  }
>(({ datatestid, ...props }) => ({
  datatestid,
  'data-track': props['data-track'],
}));

const RightTopIcon = styled('div')<{
  datatestid: string;
  'data-track': string;
  backgroundColor?: string;
}>(({ datatestid, backgroundColor, ...props }) => ({
  datatestid,
  'data-track': props['data-track'],

  backgroundColor: backgroundColor,
  position: 'absolute',
  right: '8px',
  top: '7px',
  height: '30px',
  width: '30px',
  borderRadius: '6px',
  padding: '0px 0px 2px 0px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export default function LBTButtonIllustrative({
  onClick,
  icon,
  label,
  subLabel,
  locked = false,
  iconBgColor,
  datatestid = '',
}: LBTButtonIllustrativeProps) {
  return (
    <StyledButtonIllustrative
      datatestid={`lbt-button-illustrative-${datatestid}`}
      data-track={datatestid}
      onClick={onClick}
      variant="outlined"
      className={`btnillustrative-${ID}`}
      disabled={locked}
      size="large"
      sx={{
        height: 'min-content',
        width: '100%',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {iconBgColor && (
        <RightTopIcon
          datatestid={`lbt-button-illustrative-${datatestid}`}
          data-track={datatestid}
          backgroundColor={iconBgColor}
        >
          {locked ? (
            <IconLockComponent color={COLORS.getInstance().BLACK} />
          ) : (
            <IconUnlockComponent color={COLORS.getInstance().BLACK} />
          )}
        </RightTopIcon>
      )}

      {typeof icon === 'string' ? (
        <img
          data-track={datatestid}
          src={icon}
          alt={label}
          width="48px"
          height="48px"
          style={{ filter: locked ? 'grayscale(100%)' : 'grayscale(0%)' }}
        />
      ) : (
        icon
      )}

      <LBTLabel
        content="h5"
        component="h5"
        variant="buttonLarge"
        marginTop="11px"
        color={
          !locked
            ? COLORS.getInstance().PRIMARY_DARK
            : COLORS.getInstance().NEUTRAL
        }
        datatestid={datatestid}
      >
        {label}
      </LBTLabel>
      <LBTLabel
        variant="inputHelper"
        color={
          !locked
            ? COLORS.getInstance().PRIMARY_DARK
            : COLORS.getInstance().NEUTRAL
        }
        datatestid={datatestid + '-helper'}
      >
        {subLabel}
      </LBTLabel>
    </StyledButtonIllustrative>
  );
}
