import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import React from 'react';
import styled from 'styled-components';

export type LBTButtonIconProps = IconButtonProps & {
  onClick: () => void;

  datatestid?: string;
};

const StyledButtonIcon = styled(IconButton)<
  IconButtonProps & {
    datatestid: string;
    'data-track': string;
  }
>(({ datatestid, ...props }) => ({
  datatestid,
  'data-track': props['data-track'],
}));

const LBTButtonIcon: React.FC<LBTButtonIconProps> = ({
  datatestid = '',
  ...props
}: LBTButtonIconProps) => {
  return (
    <StyledButtonIcon
      data-track={datatestid}
      datatestid={`lbt-button-${datatestid}`}
      {...props}
    />
  );
};

export default LBTButtonIcon;
