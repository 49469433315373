import { ColDef } from 'ag-grid-community';
import { useEffect, useState } from 'react';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import GridLayout from '../components/GridLayout';
import {
  AttributeMetaForm,
  LABEL_ATTRIBUTE_META_TYPE,
  LABEL_ENTITY_TYPE,
} from '../components/Forms';
import { useGrid } from '../hooks/useGrid';
import {
  getAttributesMetaByEntityTypeCallback,
  AttributeMeta,
  attributesMetaState,
  ENTITY_TYPES,
  EntityType,
  AttributeMetaType,
  snackBarState,
  SnackBarSeverity,
  deleteAttributeMetaCallback,
} from '@laborability/commons';
import { LBTSelect } from '@laborability/components';
import { FormControl } from '@mui/material';
import { appBarState } from '../recoil/states/appBar';

export function AttributeMetaPage() {
  const setSnackBar = useSetRecoilState(snackBarState);
  const [entity, setEntity] = useState<string | undefined>(undefined);
  const getAllAttributesMeta = useRecoilCallback(
    getAttributesMetaByEntityTypeCallback,
    [],
  );
  const deleteAttribute = useRecoilCallback(deleteAttributeMetaCallback, []);
  const setAppBarName = useSetRecoilState(appBarState);

  const { rowData, handleFetchAll, handleDelete } = useGrid<AttributeMeta>({
    state: attributesMetaState,
    onFetch: async () => {
      if (entity) return await getAllAttributesMeta({ entity_type: entity });
      return [];
    },
    onDelete: deleteAttribute,
  });

  const columnDefs: ColDef[] = [
    {
      field: 'id',
      maxWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: false,
      headerName: 'ID',
    },
    {
      field: 'attribute_name',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Nome',
    },
    {
      field: 'attribute_type',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Tipo',
      valueFormatter: params =>
        LABEL_ATTRIBUTE_META_TYPE[params.value as AttributeMetaType],
    },
    {
      field: 'entity_type',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Entità',
      valueFormatter: params => LABEL_ENTITY_TYPE[params.value as EntityType],
    },
  ];

  useEffect(() => {
    if (entity) handleFetchAll();
    else
      setSnackBar({
        show: true,
        message:
          'Seleziona una entità dalla lista per visualizzare gli attributi',
        severity: SnackBarSeverity.success,
        duration: 5000,
        code: 0,
      });
  }, [entity]);

  useEffect(() => {
    setAppBarName('Registrazione attributi');
  }, []);

  return (
    <GridLayout<AttributeMeta>
      tableId="attribute_meta"
      pageName="Attributi"
      columnDefs={columnDefs}
      rowData={entity ? rowData : []}
      handleFetchAll={handleFetchAll}
      FormComponent={AttributeMetaForm}
      recordNameAccessor={item => item.attribute_name ?? ''}
      formExtraParams={{ entity_type: entity }}
      hasAddNew={!!entity}
      handleDelete={handleDelete}
      gridFilters={
        <FormControl>
          <LBTSelect
            id="entity-select"
            name="entity"
            value={entity}
            items={ENTITY_TYPES.map(item => ({
              id: item,
              name: LABEL_ENTITY_TYPE[item],
            }))}
            required
            label="Seleziona tipo Entità"
            handleChange={e => setEntity(e)}
          />
        </FormControl>
      }
      emptyResponse={true}
      deleteWarning={
        "L'attributo verrà eliminato solo se non è collegato a domande."
      }
    />
  );
}
