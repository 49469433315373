import Box, { BoxProps } from '@mui/material/Box';
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import * as React from 'react';
import styled from 'styled-components';
import LBTLabel from '../Label';

export type LBTProgressBarProps = LinearProgressProps & {
  datatestid?: string;
};

const StyledProgressBar = styled(LinearProgress)<
  LinearProgressProps & {
    datatestid: string;
  }
>(({ datatestid }) => ({
  datatestid,
  '& .MuiLinearProgress-dashed': {
    animation: 'none',
  },
}));

const StyledProgressBarContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledProgressBarBox = styled(Box)<BoxProps>(() => ({
  width: '100%',
  marginRight: '10px',
}));

const StyledProgressBarLabelBox = styled(Box)<BoxProps>(() => ({
  minWidth: '35px',
}));

const LBTProgressBar: React.FC<LBTProgressBarProps> = ({
  datatestid = '',
  ...props
}: LBTProgressBarProps) => {
  return (
    <StyledProgressBarContainer>
      <StyledProgressBarBox>
        <StyledProgressBar {...props} datatestid={datatestid} />
      </StyledProgressBarBox>
      {props.variant !== 'indeterminate' && (
        <StyledProgressBarLabelBox>
          <LBTLabel variant="progressSmallLabel">{`${props.value!.toString()}%`}</LBTLabel>
        </StyledProgressBarLabelBox>
      )}
    </StyledProgressBarContainer>
  );
};

export default LBTProgressBar;
