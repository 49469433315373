import { LBTProgressSpinner } from '@laborability/components';

export default function Loader() {
  return (
    <div
      style={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        height: '20vh',
        display: 'flex',
      }}
    >
      <LBTProgressSpinner />
    </div>
  );
}
