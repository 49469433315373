import { CallbackInterface } from 'recoil';
import { setSnackbarSuccess, setSnackbarError } from '../common';
import { Id, Post, PostPageAction, Put } from '../types';
import {
  pageActionLoaderState,
  pageActionState,
  pageActionsLoaderState,
  pageActionsState,
} from '../state';
import {
  postPageAction,
  getPageActionById,
  getAllPageAction,
  putPageAction,
  deletePageAction,
} from '../actions';

export const postPageActionCallback =
  ({ set }: CallbackInterface) =>
  async (params: Post<PostPageAction>) => {
    set(pageActionLoaderState, true);
    let res = undefined;
    try {
      res = await postPageAction(params);
      set(pageActionState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Azione creata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(pageActionLoaderState, false);
    return res;
  };

export const getPageActionByIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(pageActionLoaderState, true);
    let res = undefined;
    try {
      res = await getPageActionById(params);
      set(pageActionState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(pageActionLoaderState, false);
    return res;
  };

export const getAllPageActionCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(pageActionsLoaderState, true);
    let res = undefined;
    try {
      res = await getAllPageAction(params);
      set(pageActionsState, res?.data?.items ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(pageActionsLoaderState, false);
    return res;
  };

export const putPageActionCallback =
  ({ set }: CallbackInterface) =>
  async (params: Put<PostPageAction>) => {
    set(pageActionLoaderState, true);
    let res = undefined;
    try {
      res = await putPageAction(params);
      set(pageActionState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Azione modificata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(pageActionLoaderState, false);
    return res;
  };

export const deletePageActionCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(pageActionLoaderState, true);
    let res = undefined;
    try {
      res = await deletePageAction(params);
      if (res && res.data)
        setSnackbarSuccess(set, 'Azione cancellata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(pageActionLoaderState, false);
    return res;
  };
