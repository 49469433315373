import { authGetAll, authDelete, PATH, authPost } from '../../http';
import { GetFilter, Id } from '../types';

export const postUserRegister = async () => {
  return await authPost(`register`, {});
};

export const getUserById = async ({ id }: Id) => {
  return await authGetAll(`api/v1/users/${id}`);
};

export const getUserProfile = async () => {
  return await authGetAll(`api/v1/users/profile`);
};

export const getCompanyUsers = async ({ id, skip, limit }: Id & GetFilter) => {
  return await authGetAll(`api/v1/${PATH.COMPANIES}/${id}/users`, {
    skip,
    limit,
  });
};

export const deleteUserProfile = async ({ id }: Id) => {
  return await authDelete(`api/v1/users/users/${id}`);
};
