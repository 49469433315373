import { IconsSize, IconsStyle } from '../../enums';
import { COLORS } from '../../utils';
import StyledIcon from './StyledIcon';

const IconArrowRightComponent = ({
  datatestid = '',
  size = IconsSize.LARGE,
  style = IconsStyle.OUTLINE,
  color = COLORS.getInstance().BW_GREYS_JET_BLACK,
}: {
  datatestid?: string;
  size?: IconsSize;
  style?: IconsStyle;
  color?: string;
}) => {
  return style === IconsStyle.OUTLINE ? (
    <StyledIcon
      datatestid={`lbt-icon-${datatestid}`}
      data-track={datatestid}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.04988 2.26333C6.93343 2.49856 7.02783 2.78459 7.26072 2.9022C11.4142 4.99981 13.6713 7.07193 15.7553 10.8277C16.1577 11.5564 16.1577 12.4439 15.7551 13.1725C13.6696 16.9282 11.414 19.0003 7.26072 21.0978C7.02783 21.2154 6.93343 21.5014 7.04988 21.7367C7.16632 21.9719 7.44951 22.0672 7.6824 21.9496C11.9836 19.7774 14.389 17.5798 16.5779 13.6377L16.5784 13.6368C17.1405 12.6196 17.1405 11.3804 16.5784 10.3632L16.578 10.3625C14.3906 6.42012 11.9835 4.22251 7.6824 2.05038C7.44951 1.93276 7.16632 2.02811 7.04988 2.26333Z"
        fill={color}
      />
    </StyledIcon>
  ) : (
    <StyledIcon
      datatestid={`lbt-icon-${datatestid}`}
      data-track={datatestid}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.82873 2.70412C7.57975 2.01873 6.23639 3.17838 6.5447 4.41759C7.04807 6.44164 7.77795 9.8487 7.77795 12.5C7.77795 15.1528 7.04807 18.5584 6.5447 20.5824C6.23639 21.8216 7.57975 22.9813 8.82873 22.2959C12.6071 20.2229 14.9179 17.9464 17.0399 14.2257C17.6534 13.1533 17.6534 11.8483 17.0399 10.7743C14.9195 7.05359 12.6087 4.77865 8.8303 2.70412H8.82873Z"
        fill={color}
      />
    </StyledIcon>
  );
};

export default IconArrowRightComponent;
