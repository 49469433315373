import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import {
  removeNullishOrEmptyFields,
  PageActionClass,
  getAllPageActionCallback,
  getPageActionByIdCallback,
  postPageActionCallback,
  putPageActionCallback,
  pageActionState,
  questionPagesState,
  pageActionLoaderState,
  ACTION_TYPES,
  ENTITY_TYPES,
  Modal,
  questionFlowState,
  questionPagesLoaderState,
  DestinationTypeEnum,
  QUESTIONARY_STEPS,
  ActionTypeEnum,
  ENTITY_ACTION_TYPES,
  pageActionsState,
} from '@laborability/commons';
import ModalForm from '../ModalForm';
import {
  LBTButton,
  LBTModal,
  LBTSelect,
  LBTTextField,
} from '@laborability/components';
import { useParams } from 'react-router-dom';
import { SimpleQuestionaryPageForm } from './SimpleQuestionaryPage';

const LABEL_ACTION_TYPE = {
  button: 'Bottone',
  skip: 'Skip',
  auto: 'Automatica',
};

export const LABEL_ENTITY_TYPE = {
  user: 'Utente',
  child: 'Figlio',
  partner: 'Partner',
  family: 'Famiglia',
  property: 'Proprietà',
  vehicle: 'Veicolo',
};

export const LABEL_ENTITY_ACTION = {
  loop_entity: 'Loop',
};

interface Props {
  id?: number;
  onClose?: () => void;
}

export function PageActionForm({ id, onClose }: Props) {
  const { id: page_id } = useParams();
  const postPageAction = useRecoilCallback(postPageActionCallback, []);
  const putPageAction = useRecoilCallback(putPageActionCallback, []);
  const getPageAction = useRecoilCallback(getPageActionByIdCallback, []);
  const getAllPageAction = useRecoilCallback(getAllPageActionCallback, []);
  const [current, setCurrent] = useRecoilState(pageActionState);
  const [modal, setModal] = useState<Modal | null>(null);
  const allAction = useRecoilValue(pageActionsState);
  const questionPages = useRecoilValue(questionPagesState);
  const questionFlow = useRecoilValue(questionFlowState);

  const pageActionLoader = useRecoilValue(pageActionLoaderState);
  const pagesLoader = useRecoilValue(questionPagesLoaderState);
  const isLoading = pageActionLoader || pagesLoader;

  const resetFormState = () => {
    setCurrent({
      ...new PageActionClass(),
      destination: DestinationTypeEnum.target_page,
    });
  };

  useEffect(() => {
    if (id) getPageAction({ id });
    else resetFormState();
  }, [id]);

  if (!page_id) return null;

  return (
    <>
      <ModalForm
        defaultValues={{
          ...new PageActionClass(),
          destination: DestinationTypeEnum.target_page,
        }}
        isLoading={isLoading}
        currentValues={current}
        validation={values => {
          return {
            ...(values.name === '' ? { name: 'Il nome è obbligatorio' } : {}),
          };
        }}
        handleSubmit={async values => {
          if (values.id) {
            await putPageAction({
              id: values.id,
              default_target_page_id: null,
              ...removeNullishOrEmptyFields(values),
              page_id: Number(page_id),
            });
            return;
          }
          await postPageAction({
            default_target_page_id: null,
            ...removeNullishOrEmptyFields(values),
            page_id: Number(page_id),
          });
          return;
        }}
        resetGridState={() => getAllPageAction({ id: Number(page_id) })}
        resetFormState={resetFormState}
      >
        {({ values, errors, handleBlur, setFieldValue }) => (
          <>
            <Grid item mobile={12}>
              <LBTTextField
                label="Nome"
                type="text"
                name="name"
                onChange={value => setFieldValue('name', value)}
                onBlur={handleBlur}
                value={values.name}
                required
                error={!!errors?.name}
                helperText={errors?.name}
              />
            </Grid>
            <Grid item mobile={12}>
              <LBTSelect
                id="action_type"
                name="action_type"
                value={values.action_type}
                label="Tipo"
                required
                items={ACTION_TYPES.map(type => ({
                  id: type,
                  name: LABEL_ACTION_TYPE[type],
                }))}
                handleChange={e => setFieldValue('action_type', e)}
                onBlur={handleBlur}
                error={!!errors?.action_type}
                helperText={errors?.action_type}
              />
            </Grid>
            {values.action_type === ActionTypeEnum.button && (
              <Grid item mobile={12}>
                <LBTSelect
                  id="button_style"
                  name="button_style"
                  value={values.action_meta?.button_style}
                  label="Stile"
                  required
                  items={[
                    { id: 'outlined', name: 'Outline' },
                    { id: 'contained', name: 'Filled' },
                  ]}
                  handleChange={e =>
                    setFieldValue('action_meta.button_style', e)
                  }
                  onBlur={handleBlur}
                  error={!!errors?.action_meta}
                  helperText={errors?.action_meta}
                />
              </Grid>
            )}
            <Grid
              item
              mobile={
                values.destination === DestinationTypeEnum.menu ||
                values.destination === DestinationTypeEnum.home_page
                  ? 12
                  : 3
              }
            >
              <LBTSelect
                id="destination-select"
                name="destination"
                value={values.destination}
                label="Tipo destinazione"
                handleChange={e => {
                  setFieldValue('destination', e);
                  setFieldValue('default_target_page_id', undefined);
                  setFieldValue('destination_step', undefined);
                }}
                onBlur={handleBlur}
                fullWidth
                items={[
                  {
                    id: DestinationTypeEnum.target_page,
                    name: 'Pagina',
                  },
                  {
                    id: DestinationTypeEnum.next_step,
                    name: 'Step successivo',
                  },
                  {
                    id: DestinationTypeEnum.menu,
                    name: 'Menù',
                  },
                  {
                    id: DestinationTypeEnum.home_page,
                    name: 'Homepage',
                  },
                ]}
                required
                error={!!errors?.destination}
                helperText={errors?.destination}
                style={{ minWidth: 0 }}
              />
            </Grid>
            {values.destination === DestinationTypeEnum.target_page && (
              <Grid item mobile={8}>
                <LBTSelect
                  id="default_target_page_id-select"
                  name="default_target_page_id"
                  value={values.default_target_page_id}
                  label="Pagina di destinazione default"
                  handleChange={e => setFieldValue('default_target_page_id', e)}
                  onBlur={handleBlur}
                  fullWidth
                  required
                  items={questionPages.map(item => ({
                    id: item.id as number,
                    name: item.name as string,
                  }))}
                  error={!!errors?.default_target_page_id}
                  helperText={errors?.default_target_page_id}
                />
              </Grid>
            )}
            {values.destination === DestinationTypeEnum.next_step && (
              <Grid item mobile={9}>
                <LBTSelect
                  id="destination_step-select"
                  name="destination_step"
                  value={values.destination_step}
                  label="Step di destinazione default"
                  handleChange={e => setFieldValue('destination_step', e)}
                  onBlur={handleBlur}
                  required
                  fullWidth
                  items={Array.from(Array(QUESTIONARY_STEPS).keys()).map(i => ({
                    id: i + 1,
                    name: i + 1,
                  }))}
                  error={!!errors?.destination_step}
                  helperText={errors?.destination_step}
                />
              </Grid>
            )}
            {values.destination === DestinationTypeEnum.target_page && (
              <Grid
                item
                mobile={1}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                }}
              >
                <LBTButton
                  variant="outlined"
                  onClick={() =>
                    setModal({
                      children: (
                        <SimpleQuestionaryPageForm
                          flow_id={questionFlow.id as number}
                          onClose={(id: number) => {
                            setFieldValue('default_target_page_id', id);
                            setModal(null);
                          }}
                        />
                      ),
                      title: 'Crea pagina',
                    })
                  }
                >
                  +
                </LBTButton>
              </Grid>
            )}
            <Grid item mobile={12}>
              <LBTSelect
                id="action_meta.display_order-select"
                name="action_meta.display_order"
                value={values.action_meta?.display_order ?? undefined}
                label="Ordine di visualizzazione"
                handleChange={e =>
                  setFieldValue('action_meta.display_order', e)
                }
                onBlur={handleBlur}
                fullWidth
                required
                items={Array.from(
                  Array(allAction.length + (values.id ? 0 : 1)).keys(),
                ).map(i => ({ id: i + 1, name: i + 1 }))}
                error={!!(errors?.action_meta as any)?.display_order}
                helperText={(errors?.action_meta as any)?.display_order}
              />
            </Grid>
            <Grid item mobile={6}>
              <LBTSelect
                id="action_meta.entity_type-select"
                name="action_meta.entity_type"
                value={values.action_meta?.entity_type ?? undefined}
                label="Aggiungi tipo entità"
                handleChange={e => setFieldValue('action_meta.entity_type', e)}
                onBlur={handleBlur}
                fullWidth
                items={ENTITY_TYPES.map(item => ({
                  id: item,
                  name: LABEL_ENTITY_TYPE[item],
                }))}
                error={!!(errors?.action_meta as any)?.entity_type}
                helperText={(errors?.action_meta as any)?.entity_type}
              />
            </Grid>
            <Grid item mobile={6}>
              <LBTSelect
                id="action_meta.action-select"
                name="action_meta.action"
                value={values.action_meta?.action ?? undefined}
                label="Aggiungi azione sull'entità"
                handleChange={e => setFieldValue('action_meta.action', e)}
                onBlur={handleBlur}
                fullWidth
                items={ENTITY_ACTION_TYPES.map(item => ({
                  id: item,
                  name: LABEL_ENTITY_ACTION[item],
                }))}
                error={!!(errors?.action_meta as any)?.action}
                helperText={(errors?.action_meta as any)?.action}
              />
            </Grid>
          </>
        )}
      </ModalForm>
      {modal && (
        <LBTModal
          open={!!modal}
          onXClose={() => setModal(null)}
          {...modal}
          modalStyle={{
            width: 'fit-content',
            maxWidth: '1000px',
            minWidth: '600px',
          }}
        />
      )}
    </>
  );
}
