import { createContext } from 'react';
import Keycloak from 'keycloak-js';

interface KeycloakContextProps {
  keycloak: Keycloak | undefined;
  hasQuestionaryAccess?: boolean;
}

export const KeycloakContext = createContext<KeycloakContextProps>({
  keycloak: undefined,
  hasQuestionaryAccess: false,
});
