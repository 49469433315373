import LBTProgressSpinner from '../ProgressSpinner';

export default function BackdropLoader() {
  return (
    <div
      style={{
        position: 'fixed',
        inset: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        backgroundColor: '#000000CC',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10000,
      }}
    >
      <LBTProgressSpinner />
    </div>
  );
}
