import { Company } from './company';
import { Ente } from './ente';

export enum UserRole {
  USER = 'user',
  ADMIN = 'admin',
  COMPANY = 'company',
}

export interface QuestionaryUserStatus {
  id: number;
  page_id: number;
  flow_id: number;
  flow_name: string;
  total_responses: number;
  completed: boolean;
  position?: number;
  number_of_pages?: number;
  step?: number;
}

export interface CCNLContract {
  id: number;
  name: string;
}

export type User = {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  role?: UserRole;
  company_id?: number;
  company_name?: string;
  is_superuser?: boolean;
  is_active?: boolean;
  keycloak_id?: number | string | null;
  questionary_status?: QuestionaryUserStatus[];
  companies?: Company[];
  CCNL_contracts?: CCNLContract[];
  bilateral_entities?: Ente[];
};

export class UserClass implements User {
  id = 0;
  first_name = '';
  last_name = '';
  email = '';
  password = '';
  role = UserRole.USER;
  company_id = 0;
  company_name = '';
  is_superuser = false;
  is_active = false;
  keycloak_id = null;
  questionary_status = [];
  companies = [];
  CCNL_contracts = [];
  bilateral_entities = [];
}
