import { PageAction } from '@laborability/commons';
import { LBTButton } from '../Button';

interface ActionProps {
  action: PageAction;
  evalAction: () => void;
  isButtonDisabled: boolean;
  variant: 'outlined' | 'contained';
}

export default function Action({
  action,
  evalAction,
  isButtonDisabled,
  variant,
}: ActionProps) {
  if (action.action_type === 'auto') return null;

  const isButton = action.action_type === 'button';

  return (
    <LBTButton
      variant={isButton ? variant : 'outlined'}
      onClick={evalAction}
      fullWidth
      disabled={isButton && isButtonDisabled}
    >
      {action.name}
    </LBTButton>
  );
}
