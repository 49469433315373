import { CallbackInterface } from 'recoil';
import { Contract, Id, Post, Put } from '../types';
import {
  getAllContracts,
  getContractById,
  postContract,
  putContract,
  deleteContract,
} from '../actions';
import { setSnackbarError, setSnackbarSuccess } from '../common';
import {
  contractLoaderState,
  contractState,
  contractsLoaderState,
  contractsState,
} from '../state';

export const postContractCallback =
  ({ set }: CallbackInterface) =>
  async (params: Post<Contract>) => {
    set(contractLoaderState, true);
    let res = undefined;
    try {
      res = await postContract(params);
      set(contractState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Contratto caricato con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(contractLoaderState, false);
    return res;
  };

export const getContractByIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(contractLoaderState, true);
    let res = undefined;
    try {
      res = await getContractById(params);
      set(contractState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(contractLoaderState, false);
    return res;
  };

export const getAllContractsCallback =
  ({ set }: CallbackInterface) =>
  async () => {
    set(contractsLoaderState, true);
    let res = undefined;
    try {
      res = await getAllContracts();
      set(contractsState, res?.data?.items ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(contractsLoaderState, false);
    return res;
  };

export const putContractCallback =
  ({ set }: CallbackInterface) =>
  async (params: Put<Contract>) => {
    set(contractLoaderState, true);
    let res = undefined;
    try {
      res = await putContract(params);
      set(contractState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Contratto modificato con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(contractLoaderState, false);
    return res;
  };

export const deleteContractCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(contractLoaderState, true);
    let res = undefined;
    try {
      res = await deleteContract(params);
      if (res && res.data)
        setSnackbarSuccess(set, 'Contratto cancellato con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(contractLoaderState, false);
    return res;
  };
