import { IDatasource, IGetRowsParams } from 'ag-grid-community';
import { RecoilState, useRecoilValue } from 'recoil';

interface Props<T> {
  state: RecoilState<T[]>;
  onFetch: (...params: any) => Promise<any>;
  onDelete: (id: any) => Promise<any>;
}

interface Returns<T> {
  rowData: Array<T>;
  handleFetchAll: (params?: { [x: string]: string | number }) => Promise<any>;
  handleDelete?: (id: any) => Promise<boolean | null>;
  dataSource: IDatasource;
}

export const useGrid = <T>({
  state,
  onDelete,
  onFetch,
}: Props<T>): Returns<T> => {
  const rowData = useRecoilValue(state);

  const handleFetchAll = async (params?: { [x: string]: string | number }) => {
    const res = await onFetch(params);
    return res?.data;
  };

  const handleDelete = async (id: number) => {
    if (!onDelete) return null;
    const res = await onDelete(id);
    return !!res?.data;
  };

  const dataSource = {
    rowCount: undefined,
    getRows: async (params: IGetRowsParams) => {
      // fetcho la righiesta + 1 per capire se sono presenti altri dati dopo la pagina presente
      console.log('asking for ' + params.startRow + ' to ' + params.endRow);
      const skip = params.startRow ? params.startRow - 1 : 0;
      const limit = params.endRow - skip + 1;

      const res = await onFetch({ skip, limit });
      if (!res || !res.data) params.failCallback();
      // controllo le righe presenti, se sono pari o inferiori alla righiesta vuol dire che non c'è nessun dato dopo
      let lastRow =
        skip + res.data.length <= params.endRow ? skip + res.data.length : -1;
      console.log({ d: res?.data, l: res?.data.length, lastRow });
      params.successCallback(
        lastRow === -1 ? res.data.slice(0, -1) : res.data,
        lastRow,
      );
      return res?.data;
    },
  };

  return { handleFetchAll, handleDelete, rowData, dataSource };
};
