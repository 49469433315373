import { Metric, MetricData } from '@laborability/commons';
import { Line } from 'react-chartjs-2';
import { ANALYTIC_METRIC_LABEL, DateAggregationEnum } from './types';
import dayjs from 'dayjs';

interface Props {
  metricData: Metric;
  borderColor: string;
  backgroundColor: string;
  dateAggregation: DateAggregationEnum;
}

export default function Chart({
  metricData,
  borderColor,
  backgroundColor,
  dateAggregation,
}: Props) {
  const getStartOfRange = (date: string) => {
    return dayjs(date, 'YYYY-MM-DD')
      .startOf(dateAggregation)
      .locale('it')
      .format(
        dateAggregation === DateAggregationEnum.month
          ? 'MMMM YYYY'
          : 'DD-MM-YYYY',
      );
  };

  const groupValues = (data: MetricData[]) => {
    return data.reduce((prev: { x: string; y: number }[], current) => {
      const currentDate = getStartOfRange(current.date);
      const prevDate = prev.find(item => item.x === currentDate);
      if (prevDate) {
        prevDate.y += current.value;
        return prev;
      } else return [...prev, { x: currentDate, y: current.value }];
    }, []);
  };

  return (
    <Line
      data={{
        datasets: metricData.dimensions.map(dimension => ({
          label: ANALYTIC_METRIC_LABEL[metricData.metric],
          borderColor,
          backgroundColor,
          data: groupValues(dimension.data),
        })),
      }}
      options={{
        plugins: {
          legend: {
            display: true,
            onClick: () => {},
          },
        },
      }}
    />
  );
}
