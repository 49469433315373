import { PageAction, PageActionClass } from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const pageActionState = atom<PageAction>({
  key: getUUID(),
  default: new PageActionClass(),
});

export const pageActionsState = atom<PageAction[]>({
  key: getUUID(),
  default: [],
});

export const pageActionLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const pageActionsLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
