import { Municipalities, Province, Region } from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const regionsState = atom<Region[]>({
  key: getUUID(),
  default: [],
});

export const provincesState = atom<Province[]>({
  key: getUUID(),
  default: [],
});

export const municipalitiesState = atom<Municipalities[]>({
  key: getUUID(),
  default: [],
});

export const regionsLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const provincesLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const municipalitiesLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
