import { authPost, PATH, authGetAll, authPut, authDelete } from '../../http';
import { GetFilter, Id, Post, Put, Tag } from '../types';

export const postTag = async (item: Post<Tag>) => {
  return await authPost(`api/v1/${PATH.MISURE}/${PATH.TAGS}/`, item);
};

export const getTagById = async ({ id }: Id) => {
  return await authGetAll(
    `api/v1/${PATH.MISURE}/${PATH.PUBLIC}/${PATH.TAGS}/${id}`,
  );
};

export const getAllTags = async ({ skip, limit }: GetFilter) => {
  return await authGetAll(
    `api/v1/${PATH.MISURE}/${PATH.PUBLIC}/${PATH.TAGS}/`,
    { skip, limit },
  );
};

export const putTag = async (item: Put<Tag>) => {
  return await authPut(`api/v1/${PATH.MISURE}/${PATH.TAGS}`, item);
};

export const deleteTag = async ({ id }: Id) => {
  return await authDelete(`api/v1/${PATH.MISURE}/${PATH.TAGS}/${id}`);
};
