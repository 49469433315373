import { Metric, MetricClass, Tag, TagClass } from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const metricState = atom<Metric>({
  key: getUUID(),
  default: new MetricClass(),
});

export const metricLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
