import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import {
  ContractClass,
  contractLoaderState,
  contractState,
  getAllContractsCallback,
  getContractByIdCallback,
  postContractCallback,
  putContractCallback,
  removeNullishOrEmptyFields,
} from '@laborability/commons';
import { LBTTextField } from '@laborability/components';
import ModalForm from '../ModalForm';

type Props = {
  id?: number;
};

export function ContractForm({ id }: Props) {
  const postContract = useRecoilCallback(postContractCallback, []);
  const putContract = useRecoilCallback(putContractCallback, []);
  const getContract = useRecoilCallback(getContractByIdCallback, []);
  const getAllContract = useRecoilCallback(getAllContractsCallback, []);
  const [current, setCurrent] = useRecoilState(contractState);
  const contractLoader = useRecoilValue(contractLoaderState);

  const resetFormState = () => {
    setCurrent(new ContractClass());
  };

  useEffect(() => {
    if (id) getContract({ id });
    else resetFormState();
  }, [id]);

  return (
    <ModalForm
      defaultValues={new ContractClass()}
      isLoading={contractLoader}
      currentValues={current}
      validation={values => {
        return {
          ...(values.name === ''
            ? { name: 'Il nome del Contract è obbligatorio' }
            : {}),
        };
      }}
      handleSubmit={async values => {
        if (values.id)
          return await putContract({
            id: values.id,
            ...removeNullishOrEmptyFields(values),
          });
        return await postContract(removeNullishOrEmptyFields(values));
      }}
      resetGridState={getAllContract}
      resetFormState={resetFormState}
    >
      {({ values, errors, setFieldValue, handleBlur }) => (
        <Grid item mobile={12}>
          <LBTTextField
            label="Nome"
            type="text"
            name="name"
            onChange={value => setFieldValue('name', value)}
            onBlur={handleBlur}
            value={values.name}
            required
            error={!!errors?.name}
            helperText={errors?.name}
          />
        </Grid>
      )}
    </ModalForm>
  );
}
