import { authPost, PATH, authDelete, authPut, authGetAll } from '../../http';
import { Id, Post, PostQuestion, Put } from '../types';

export const postQuestion = async (item: Post<PostQuestion>) => {
  return await authPost(`api/v1/${PATH.QUESTIONARIES}/${PATH.QUESTIONS}`, item);
};

export const getQuestionById = async ({ id }: Id) => {
  return await authGetAll(
    `api/v1/${PATH.QUESTIONARIES}/${PATH.QUESTIONS}/${id}`,
  );
};

export const getAllQuestion = async ({ id }: Id) => {
  return await authGetAll(
    `api/v1/${PATH.QUESTIONARIES}/${PATH.PAGES}/${id}/${PATH.QUESTIONS}`,
  );
};

export const putQuestion = async (item: Put<PostQuestion>) => {
  return await authPut(`api/v1/${PATH.QUESTIONARIES}/${PATH.QUESTIONS}`, item);
};

export const deleteQuestion = async ({ id }: Id) => {
  return await authDelete(
    `api/v1/${PATH.QUESTIONARIES}/${PATH.QUESTIONS}/${id}`,
  );
};
