import Button, { ButtonProps } from '@mui/material/Button';
import React from 'react';
import styled from 'styled-components';
import { TypographyProps } from '@mui/material';
import LBTLabel from '../Label';

export type LBTButtonProps = ButtonProps & {
  onClick: () => void;
  datatestid?: string;
  maxWidth?: string;
  textProps?: TypographyProps;
};

const StyledButton = styled(Button)<
  ButtonProps & {
    datatestid: string;
    'data-track': string;
    maxWidth?: string;
  }
>(({ datatestid, maxWidth, ...props }) => {
  return {
    datatestid,
    'data-track': props['data-track'],
    maxWidth: maxWidth,
    '& .MuiButton-startIcon > svg:first-child': {
      filter: props.disabled ? 'contrast(0%)' : 'contrast(100%)',
    },
    '& .MuiButton-endIcon > svg:last-child': {
      filter: props.disabled ? 'contrast(0%)' : 'contrast(100%)',
    },
  };
});

const LBTButton: React.FC<LBTButtonProps> = ({
  datatestid = '',
  children,
  textProps,
  size,
  maxWidth,
  ...props
}: LBTButtonProps) => {
  const getVariant = () => {
    if (size === 'small') return 'buttonSmall';
    if (size === 'medium') return 'buttonMedium';
    return 'buttonLarge';
  };

  return (
    <StyledButton
      data-track={datatestid}
      datatestid={`lbt-button-${datatestid}`}
      maxWidth={maxWidth}
      size={size}
      {...props}
    >
      <LBTLabel
        datatestid={`lbt-button-label-${datatestid}`}
        data-track={datatestid}
        {...textProps}
        variant={getVariant()}
      >
        {children}
      </LBTLabel>
    </StyledButton>
  );
};

export default LBTButton;
