import { Grid } from '@mui/material';
import { useEffect } from 'react';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import {
  removeNullishOrEmptyFields,
  postQuestionFlowCallback,
  getQuestionFlowByIdCallback,
  getAllQuestionFlowCallback,
  questionFlowState,
  QuestionFlowClass,
  putQuestionFlowCallback,
  questionPagesState,
  questionFlowLoaderState,
  QUESTIONARY_STEPS,
} from '@laborability/commons';
import ModalForm from '../ModalForm';
import { LBTSelect, LBTTextField } from '@laborability/components';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../Routes';
import ImageInput from '../ImageInput';

interface Props {
  id?: number;
  onClose?: () => void;
}

export function QuestionaryForm({ id, onClose }: Props) {
  const navigate = useNavigate();
  const postFlow = useRecoilCallback(postQuestionFlowCallback, []);
  const putFlow = useRecoilCallback(putQuestionFlowCallback, []);
  const getFlow = useRecoilCallback(getQuestionFlowByIdCallback, []);
  const getAllFlow = useRecoilCallback(getAllQuestionFlowCallback, []);
  const [current, setCurrent] = useRecoilState(questionFlowState);
  const setPages = useSetRecoilState(questionPagesState);

  const questionFlowLoader = useRecoilValue(questionFlowLoaderState);
  const isLoading = questionFlowLoader;

  const resetFormState = () => {
    setCurrent(new QuestionFlowClass());
    setPages([]);
  };

  useEffect(() => {
    if (id) getFlow({ id });
    else resetFormState();
  }, [id]);

  return (
    <ModalForm
      defaultValues={new QuestionFlowClass()}
      isLoading={isLoading}
      currentValues={current}
      validation={values => {
        return {
          ...(values.name === '' ? { name: 'Il nome è obbligatorio' } : {}),
          ...(!values.step ? { step: 'Lo step è obbligatorio' } : {}),
        };
      }}
      handleSubmit={async values => {
        delete values.pages;
        if (values.id)
          return await putFlow({
            id: values.id,
            ...removeNullishOrEmptyFields(values),
          });
        const res = await postFlow(removeNullishOrEmptyFields(values));
        if (res?.data) {
          onClose?.();
          navigate(`/${APP_ROUTES.QUESTION_FLOW}/${res.data.id}`);
        }
        return;
      }}
      resetGridState={getAllFlow}
      resetFormState={resetFormState}
    >
      {({ values, errors, handleBlur, setFieldValue }) => (
        <>
          <Grid item mobile={12} sx={{ marginBottom: '16px' }}>
            <LBTTextField
              label="Nome"
              type="text"
              name="name"
              onChange={value => setFieldValue('name', value)}
              onBlur={handleBlur}
              value={values.name}
              required
              error={!!errors?.name}
              helperText={errors?.name}
            />
          </Grid>
          <Grid item mobile={12} sx={{ marginBottom: '16px' }}>
            <LBTTextField
              label="Descrizione"
              type="text"
              name="description"
              onChange={value => setFieldValue('description', value)}
              onBlur={handleBlur}
              value={values.description}
              required
              error={!!errors?.description}
              helperText={errors?.description}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTSelect
              id="step-select"
              name="step"
              value={values.step}
              label="Step"
              handleChange={e => {
                setFieldValue('step', e);
              }}
              onBlur={handleBlur}
              fullWidth
              items={Array.from(Array(QUESTIONARY_STEPS + 11).keys()).map(
                i => ({
                  id: i + 1,
                  name: i + 1,
                }),
              )}
              error={!!errors?.step}
              helperText={errors?.step}
            />
          </Grid>
          <Grid item mobile={12}>
            <ImageInput
              value={values?.image ?? ''}
              onChange={value => setFieldValue('image', value)}
              error={!!errors?.image}
              helperText={errors?.image}
            />
          </Grid>
          <Grid item mobile={12} sx={{ marginBottom: '16px' }}>
            <LBTTextField
              label="Titolo alert"
              type="text"
              name="info_title"
              onChange={value => setFieldValue('info_title', value)}
              onBlur={handleBlur}
              value={values.info_title}
              required
              error={!!errors?.info_title}
              helperText={errors?.info_title}
            />
          </Grid>
          <Grid item mobile={12} sx={{ marginBottom: '16px' }}>
            <LBTTextField
              label="Descrizione alert"
              type="text"
              name="info_text"
              onChange={value => setFieldValue('info_text', value)}
              onBlur={handleBlur}
              value={values.info_text}
              required
              error={!!errors?.info_text}
              helperText={errors?.info_text}
            />
          </Grid>
        </>
      )}
    </ModalForm>
  );
}
