import { QuestionPage } from './questionPage';

export interface QuestionFlow {
  id?: number;
  name?: string;
  step?: number;
  image?: string;
  description?: string;
  pages?: QuestionPage[];
  number_of_pages?: number;
  info_title?: string;
  info_text?: string;
}

export class QuestionFlowClass implements QuestionFlow {
  id = 0;
  name = '';
  step = 0;
  image = '';
  description = '';
  pages = [];
  number_of_pages = 0;
  info_title = '';
  info_text = '';
}

export const QUESTIONARY_STEPS = 9;
export const STATIC_QUESTIONARY_NUMBER = 3;

export interface QuestionFlowGraphArc {
  name: string;
  source_page_id: number;
  target_page_id: number;
  element_id: number;
  element_type: string;
}

export interface QuestionFlowGraphItem {
  name: string;
  page_id: number;
  arcs: QuestionFlowGraphArc[];
}
