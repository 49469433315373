import { Question, QuestionClass } from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const questionState = atom<Question>({
  key: getUUID(),
  default: new QuestionClass(),
});

export const questionsState = atom<Question[]>({
  key: getUUID(),
  default: [],
});

export const questionLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const questionsLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
