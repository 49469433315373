import { Radio, RadioProps, Stack } from '@mui/material';
import LBTLabel from '../Label';
import { COLORS } from '../../utils';
import styled from 'styled-components';

export type LBTRadioProps = RadioProps & {
  label?: string;
  direction?: 'row' | 'row-reverse';
  datatestid?: string;
};

const StyledRadioButton = styled(Radio)<
  RadioProps & {
    datatestid?: string;
  }
>(({ datatestid }) => ({
  datatestid,
}));

export default function LBTRadio({
  label,
  direction,
  datatestid,
  disabled,
  ...props
}: LBTRadioProps) {
  return (
    <Stack direction={direction} justifyContent="center" alignItems="center">
      <StyledRadioButton
        datatestid={`lbt-radio-${datatestid}`}
        disabled={disabled}
        {...props}
      />
      {label && (
        <LBTLabel
          color={
            disabled
              ? COLORS.getInstance().DISABLED
              : COLORS.getInstance().BLACK
          }
          variant="inputFormLabel"
          component="label"
        >
          {label}
        </LBTLabel>
      )}
    </Stack>
  );
}
