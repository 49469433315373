import { createHashRouter, Navigate, RouterProvider } from 'react-router-dom';
import Layout from './components/Layout';
import {
  UsersPage,
  TagsPage,
  CategoriesPage,
  BenefitsPage,
  CompaniesPage,
  NoPage404,
  MisurePage,
  EntesPage,
  ContractsPage,
  QuestionaryPage,
  QuestionFlowPage,
  Home,
  QuestionPagePage,
  ActionConditionPage,
  QuestionConditionPage,
  AttributeMetaPage,
  DomainsPage,
  DomainValuesPage,
  MisureConditionPage,
  PagePreview,
} from './pages';

export const APP_ROUTES = {
  HOME: 'home',
  USER: 'user',
  TAGS: 'tags',
  CATEGORIES: 'categories',
  BENEFITS: 'benefits',
  COMPANIES: 'companies',
  MEASURES: 'measures',
  MEASURE_CONDITION: 'measure-condition',
  ENTES: 'enti',
  CONTRACTS: 'contracts',
  QUESTIONARY: 'questionary',
  QUESTION_FLOW: 'question-flow',
  QUESTION_PAGE: 'question-page',
  ACTION_CONDITION: 'action-condition',
  QUESTION_CONDITION: 'question-condition',
  DOMAIN: 'domain',
  DOMAIN_VALUES: 'domain-values',
  ATTRIBUTES: 'attributes',
  PAGE_PREVIEW: 'page_preview',
};

export default function AppRoutes() {
  const router = createHashRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        { index: true, path: '/', element: <Navigate to={APP_ROUTES.HOME} /> },
        {
          path: APP_ROUTES.HOME,
          element: <Home />,
        },
        {
          path: APP_ROUTES.USER,
          element: <UsersPage />,
        },
        {
          path: APP_ROUTES.TAGS,
          element: <TagsPage />,
        },
        {
          path: APP_ROUTES.CATEGORIES,
          element: <CategoriesPage />,
        },
        {
          path: APP_ROUTES.BENEFITS,
          element: <BenefitsPage />,
        },
        {
          path: APP_ROUTES.COMPANIES,
          element: <CompaniesPage />,
        },
        {
          path: APP_ROUTES.MEASURES,
          element: <MisurePage />,
        },
        {
          path: `${APP_ROUTES.MEASURE_CONDITION}/:id`,
          element: <MisureConditionPage />,
        },
        {
          path: APP_ROUTES.ENTES,
          element: <EntesPage />,
        },
        {
          path: APP_ROUTES.CONTRACTS,
          element: <ContractsPage />,
        },
        {
          path: APP_ROUTES.QUESTIONARY,
          element: <QuestionaryPage />,
        },
        {
          path: `${APP_ROUTES.QUESTION_FLOW}/:id`,
          element: <QuestionFlowPage />,
        },
        {
          path: `${APP_ROUTES.QUESTION_PAGE}/:id`,
          element: <QuestionPagePage />,
        },
        {
          path: `${APP_ROUTES.ACTION_CONDITION}/:id`,
          element: <ActionConditionPage />,
        },
        {
          path: `${APP_ROUTES.QUESTION_CONDITION}/:id`,
          element: <QuestionConditionPage />,
        },
        {
          path: APP_ROUTES.DOMAIN,
          element: <DomainsPage />,
        },
        {
          path: `${APP_ROUTES.DOMAIN}/:id`,
          element: <DomainsPage />,
        },
        {
          path: `${APP_ROUTES.DOMAIN_VALUES}/:domain`,
          element: <DomainValuesPage />,
        },
        {
          path: `${APP_ROUTES.DOMAIN_VALUES}/:domain/:parent_id`,
          element: <DomainValuesPage />,
        },
        {
          path: APP_ROUTES.ATTRIBUTES,
          element: <AttributeMetaPage />,
        },
        {
          path: `${APP_ROUTES.PAGE_PREVIEW}/:flow_id/:page_id`,
          element: <PagePreview />,
        },
        {
          path: '/*',
          element: <NoPage404 />,
        },
      ],
    },
    { index: true, path: '/*', element: <Navigate to={APP_ROUTES.HOME} /> },
  ]);

  return <RouterProvider router={router} />;
}
