import { CallbackInterface } from 'recoil';
import { Company, GetFilter, Id, Post, Put } from '../types';
import {
  getAllCompanies,
  postCompany,
  getCompanyById,
  deleteCompany,
  putCompany,
} from '../actions';
import { setSnackbarError, setSnackbarSuccess } from '../common';
import {
  companiesLoaderState,
  companiesState,
  companyLoaderState,
  companyState,
} from '../state';

export const postCompanyCallback =
  ({ set }: CallbackInterface) =>
  async (params: Post<Company>) => {
    set(companyLoaderState, true);
    let res = undefined;
    try {
      res = await postCompany(params);
      set(companyState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(
          set,
          'Registrazione eseguita correttamente',
          res.status,
        );
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(companyLoaderState, false);
    return res;
  };

export const getCompanyByIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(companyLoaderState, true);
    let res = undefined;
    try {
      res = await getCompanyById(params);
      set(companyState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(companyLoaderState, false);
    return res;
  };

export const getAllCompaniesCallback =
  ({ set }: CallbackInterface) =>
  async (params: GetFilter = {}) => {
    set(companiesLoaderState, true);
    let res = undefined;
    try {
      res = await getAllCompanies(params);
      set(companiesState, res?.data?.items ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(companiesLoaderState, false);
    return res;
  };

export const putCompanyCallback =
  ({ set }: CallbackInterface) =>
  async (params: Put<Company>) => {
    set(companyLoaderState, true);
    let res = undefined;
    try {
      res = await putCompany(params);
      set(companyState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Modifica eseguita correttamente', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(companyLoaderState, false);
    return res;
  };

export const deleteCompanyCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(companyLoaderState, true);
    let res = undefined;
    try {
      res = await deleteCompany(params);
      if (res && res.data)
        setSnackbarSuccess(
          set,
          'Compagnia cancellata correttamente',
          res.status,
        );
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(companyLoaderState, false);
    return res;
  };
