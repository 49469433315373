import { CallbackInterface } from 'recoil';
import { GetFilter, Id, Post, Put, Tag } from '../types';
import { postTag, getTagById, getAllTags, putTag, deleteTag } from '../actions';
import { setSnackbarSuccess, setSnackbarError } from '../common';
import { tagLoaderState, tagState, tagsLoaderState, tagsState } from '../state';

export const postTagCallback =
  ({ set }: CallbackInterface) =>
  async (params: Post<Tag>) => {
    set(tagLoaderState, true);
    let res = undefined;
    try {
      res = await postTag(params);
      set(tagState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Tag caricato con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(tagLoaderState, false);
    return res;
  };

export const getTagByIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(tagLoaderState, true);
    let res = undefined;
    try {
      res = await getTagById(params);
      set(tagState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(tagLoaderState, false);
    return res;
  };

export const getAllTagsCallback =
  ({ set }: CallbackInterface) =>
  async (params: GetFilter = {}) => {
    set(tagsLoaderState, true);
    let res = undefined;
    try {
      res = await getAllTags(params);
      set(tagsState, res?.data?.items ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(tagsLoaderState, false);
    return res;
  };

export const putTagCallback =
  ({ set }: CallbackInterface) =>
  async (params: Put<Tag>) => {
    set(tagLoaderState, true);
    let res = undefined;
    try {
      res = await putTag(params);
      set(tagState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Tag modificato con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(tagLoaderState, false);
    return res;
  };

export const deleteTagCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(tagLoaderState, true);
    let res = undefined;
    try {
      res = await deleteTag(params);
      if (res && res.data)
        setSnackbarSuccess(set, 'Tag cancellato con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(tagLoaderState, false);
    return res;
  };
