import { Box, Button, Stack } from '@mui/material';

export const ImageView: React.FC<{
  image: string;
  onClose: () => void;
}> = ({ image, onClose }) => {
  return (
    <Stack display={'flex'} width="100%" height="70vh" alignItems="center">
      <img
        src={image}
        style={{
          height: '100%',
          width: 'auto',
          display: 'flex',
        }}
      />
      <Box>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Chiudi
        </Button>
      </Box>
    </Stack>
  );
};
