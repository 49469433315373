import { Grid } from '@mui/material';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import {
  postDomainCallback,
  domainLoaderState,
  getAllDomainKeysCallback,
  patchDomainCallback,
  domainKeysLoaderState,
  Domain,
  removeNullishOrEmptyFields,
  DomainKey,
} from '@laborability/commons';
import ModalForm from '../ModalForm';
import { LBTTextField } from '@laborability/components';
import { APP_ROUTES } from '../../Routes';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

interface Props {
  id?: string;
  onClose?: () => void;
  extraParams: {
    parent_id?: string;
    domain_id?: string;
    rowData?: DomainKey[];
  };
}

export function DomainForm({ id, onClose, extraParams }: Props) {
  const navigate = useNavigate();
  const postDomain = useRecoilCallback(postDomainCallback, []);
  const patchDomain = useRecoilCallback(patchDomainCallback, []);
  const getAllDomain = useRecoilCallback(getAllDomainKeysCallback, []);
  const { parent_id, domain_id } = extraParams;
  const defaultValues: Domain = {
    id: id,
    value: '',
    domain: '',
    description:
      extraParams?.rowData?.find(item => item.domain === id)?.description ?? '',
    ...removeNullishOrEmptyFields({ domain: id }),
    parent_id: parent_id ? Number(parent_id) : undefined,
  };
  const [current, setCurrent] = useState(defaultValues);

  const domainLoader = useRecoilValue(domainLoaderState);
  const domainKeysLoader = useRecoilValue(domainKeysLoaderState);
  const isLoading = domainLoader || domainKeysLoader;

  const resetFormState = () => {
    setCurrent(defaultValues);
  };

  return (
    <ModalForm
      defaultValues={defaultValues}
      isLoading={isLoading}
      currentValues={current}
      validation={values => {
        return {
          ...(values.domain === ''
            ? { domain: 'Il nome del dominio è obbligatorio' }
            : {}),
          ...(values.value === '' && !id
            ? { value: 'È necessario inserire almeno un valore nel dominio' }
            : {}),
        };
      }}
      handleSubmit={async (values: Domain) => {
        if (id) {
          return await patchDomain({
            domain_key: id,
            new_domain_key: values.domain,
            new_domain_description: values.description,
          });
        }
        const res = await postDomain(values);
        if (res?.data) {
          if (parent_id)
            navigate(`/${APP_ROUTES.DOMAIN_VALUES}/${res?.data.domain}`);
        }
        return;
      }}
      resetGridState={() => getAllDomain({ domain_id })}
      resetFormState={resetFormState}
    >
      {({ values, errors, setFieldValue, handleBlur }) => (
        <>
          <Grid item mobile={12}>
            <LBTTextField
              label="Dominio"
              type="text"
              name="domain"
              onChange={value => setFieldValue('domain', value)}
              onBlur={handleBlur}
              value={values.domain}
              required
              error={!!errors?.domain}
              helperText={errors?.domain}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Descrizione"
              type="text"
              name="description"
              onChange={value => setFieldValue('description', value)}
              onBlur={handleBlur}
              value={values.description}
              error={!!errors?.description}
              helperText={errors?.description}
            />
          </Grid>
          {!id && (
            <Grid item mobile={12}>
              <LBTTextField
                label="Valore"
                type="text"
                name="value"
                onChange={value => setFieldValue('value', value)}
                onBlur={handleBlur}
                value={values.value}
                required
                error={!!errors?.value}
                helperText={errors?.value}
              />
            </Grid>
          )}
        </>
      )}
    </ModalForm>
  );
}
