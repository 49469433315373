import { CallbackInterface } from 'recoil';
import {
  getUserById,
  getUserProfile,
  deleteUserProfile,
  getCompanyUsers,
  postUserRegister,
} from '../actions';
import { setSnackbarError } from '../common';
import { GetFilter, Id } from '../types';
import {
  currentUserState,
  userLoaderState,
  userState,
  usersLoaderState,
  usersState,
} from '../state';

export const postUserRegisterCallback =
  ({ set }: CallbackInterface) =>
  async () => {
    set(userLoaderState, true);
    let res = undefined;
    try {
      res = await postUserRegister();
      set(userState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(userLoaderState, false);
    return res;
  };

export const getUserByIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(userLoaderState, true);
    let res = undefined;
    try {
      res = await getUserById(params);
      set(userState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(userLoaderState, false);
    return res;
  };

export const getUserProfileCallback =
  ({ set }: CallbackInterface) =>
  async () => {
    set(userLoaderState, true);
    let res = undefined;
    try {
      res = await getUserProfile();
      set(currentUserState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(userLoaderState, false);
    return res;
  };

export const getCompanyUsersCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id & GetFilter) => {
    set(usersLoaderState, true);
    let res = undefined;
    try {
      res = await getCompanyUsers(params);
      set(usersState, res?.data?.items ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(usersLoaderState, false);
    return res;
  };

export const deleteUserProfileCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(userLoaderState, true);
    let res = undefined;
    try {
      res = await deleteUserProfile(params);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(userLoaderState, false);
    return res;
  };
