import { acceptedImageType, getBase64 } from '@laborability/commons';
import { Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import styled from 'styled-components';

const StyledTextField = styled(TextField)<TextFieldProps>(() => ({
  '& .MuiInputBase-root': {
    height: '52px',
  },
}));

interface Props {
  value: string;
  error?: boolean;
  helperText?: string;
  onChange: (v: string) => void;
}

export default function ImageInput({
  value,
  error,
  helperText,
  onChange,
  ...props
}: Props & TextFieldProps) {
  return (
    <>
      <Stack flexDirection={'row'} display={'flex'}>
        <Typography
          display={'flex'}
          flex={0.5}
          alignItems={'center'}
          justifyContent={'left'}
        >
          Immagine
        </Typography>
        <StyledTextField
          fullWidth
          type="file"
          inputProps={acceptedImageType}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files && event.target.files[0];
            if (!file) return;
            getBase64(file).then((data: any) => onChange(data as any));
          }}
          error={error}
          helperText={helperText}
          {...props}
        />
      </Stack>
      <img src={value} style={{ maxHeight: '100px' }} />
    </>
  );
}
