import { CallbackInterface } from 'recoil';
import { Ente, GetAllEnte, GetFilter, Id, Post, Put } from '../types';
import {
  getAllEnte,
  getEnteById,
  postEnte,
  putEnte,
  deleteEnte,
} from '../actions';
import { setSnackbarError, setSnackbarSuccess } from '../common';
import {
  agenciesLoaderState,
  agenciesState,
  agencyLoaderState,
  agencyState,
} from '../state';

export const postEnteCallback =
  ({ set }: CallbackInterface) =>
  async (params: Post<Ente>) => {
    set(agencyLoaderState, true);
    let res = undefined;
    try {
      res = await postEnte(params);
      set(agencyState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Ente caricato con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(agencyLoaderState, false);
    return res;
  };

export const getEnteByIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(agencyLoaderState, true);
    let res = undefined;
    try {
      res = await getEnteById(params);
      set(agencyState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(agencyLoaderState, false);
    return res;
  };

export const getAllEnteCallback =
  ({ set }: CallbackInterface) =>
  async (params: GetFilter & GetAllEnte = {}) => {
    set(agenciesLoaderState, true);
    let res = undefined;
    try {
      res = await getAllEnte(params);
      set(agenciesState, res?.data?.items ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(agenciesLoaderState, false);
    return res;
  };

export const putEnteCallback =
  ({ set }: CallbackInterface) =>
  async (params: Put<Ente>) => {
    set(agencyLoaderState, true);
    let res = undefined;
    try {
      res = await putEnte(params);
      set(agencyState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Ente modificato con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(agencyLoaderState, false);
    return res;
  };

export const deleteEnteCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(agencyLoaderState, true);
    let res = undefined;
    try {
      res = await deleteEnte(params);
      if (res && res.data)
        setSnackbarSuccess(set, 'Ente cancellato con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(agencyLoaderState, false);
    return res;
  };
