import { authPost, PATH, authGetAll, authDelete, authPut } from '../../http';
import { Id, Post, PostPageAction, Put } from '../types';

export const postPageAction = async (item: Post<PostPageAction>) => {
  return await authPost(`api/v1/${PATH.QUESTIONARIES}/${PATH.ACTIONS}`, item);
};

export const getPageActionById = async ({ id }: Id) => {
  return await authGetAll(`api/v1/${PATH.QUESTIONARIES}/${PATH.ACTIONS}/${id}`);
};

export const getAllPageAction = async ({ id }: Id) => {
  return await authGetAll(
    `api/v1/${PATH.QUESTIONARIES}/${PATH.PAGES}/${id}/${PATH.ACTIONS}`,
  );
};

export const putPageAction = async (item: Put<PostPageAction>) => {
  return await authPut(`api/v1/${PATH.QUESTIONARIES}/${PATH.ACTIONS}`, item);
};

export const deletePageAction = async ({ id }: Id) => {
  return await authDelete(`api/v1/${PATH.QUESTIONARIES}/${PATH.ACTIONS}/${id}`);
};
