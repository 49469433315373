import styled from 'styled-components';

const StyledIcon = styled('svg')<{
  datatestid?: string;
  'data-track': string;
}>(({ datatestid, ...props }) => ({
  datatestid,
  'data-track': props['data-track'],
}));

export default StyledIcon;
