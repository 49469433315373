import { Tag, TagClass } from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const tagState = atom<Tag>({
  key: getUUID(),
  default: new TagClass(),
});

export const tagsState = atom<Tag[]>({
  key: getUUID(),
  default: [],
});

export const tagLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const tagsLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
