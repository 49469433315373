import { User, UserClass } from '../types';
import { atom } from 'recoil';
import { getUUID } from '../../utils';

export const currentUserState = atom<User>({
  key: getUUID(),
  default: {},
});

export const userState = atom<User>({
  key: getUUID(),
  default: new UserClass(),
});

export const usersState = atom<User[]>({
  key: getUUID(),
  default: [],
});

export const userLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});

export const usersLoaderState = atom<boolean>({
  key: getUUID(),
  default: false,
});
