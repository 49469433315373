import { CallbackInterface } from 'recoil';
import { Category, GetFilter, Id, Post, Put } from '../types';
import {
  getAllCategories,
  getCategoryById,
  postCategory,
  putCategory,
  deleteCategory,
  getSubCategories,
  getCategoriesHomePage,
} from '../actions';
import { setSnackbarError, setSnackbarSuccess } from '../common';
import {
  categoriesLoaderState,
  categoriesState,
  categoryLoaderState,
  categoryState,
} from '../state';

export const postCategoryCallback =
  ({ set }: CallbackInterface) =>
  async (params: Post<Category>) => {
    set(categoryLoaderState, true);
    let res = undefined;
    try {
      res = await postCategory(params);
      set(categoryState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Categoria caricata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(categoryLoaderState, false);
    return res;
  };

export const getCategoryByIdCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(categoryLoaderState, true);
    let res = undefined;
    try {
      res = await getCategoryById(params);
      set(categoryState, res?.data ?? {});
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(categoryLoaderState, false);
    return res;
  };

export const getCategoriesHomePageCallback =
  ({ set }: CallbackInterface) =>
  async (params: GetFilter = {}) => {
    set(categoriesLoaderState, true);
    let res = undefined;
    try {
      res = await getCategoriesHomePage(params);
      set(categoriesState, res?.data?.items ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(categoriesLoaderState, false);
    return res;
  };

export const getAllCategoriesCallback =
  ({ set }: CallbackInterface) =>
  async (params: GetFilter = {}) => {
    set(categoriesLoaderState, true);
    let res = undefined;
    try {
      res = await getAllCategories(params);
      set(categoriesState, res?.data?.items ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(categoriesLoaderState, false);
    return res;
  };

export const getSubCategoriesCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id & GetFilter) => {
    set(categoriesLoaderState, true);
    let res = undefined;
    try {
      res = await getSubCategories(params);
      set(categoriesState, res?.data ?? []);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(categoriesLoaderState, false);
    return res;
  };

export const putCategoryCallback =
  ({ set }: CallbackInterface) =>
  async (params: Put<Category>) => {
    set(categoryLoaderState, true);
    let res = undefined;
    try {
      res = await putCategory(params);
      set(categoryState, res?.data ?? {});
      if (res && res.data)
        setSnackbarSuccess(set, 'Categoria modificata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(categoryLoaderState, false);
    return res;
  };

export const deleteCategoryCallback =
  ({ set }: CallbackInterface) =>
  async (params: Id) => {
    set(categoryLoaderState, true);
    let res = undefined;
    try {
      res = await deleteCategory(params);
      if (res && res.data)
        setSnackbarSuccess(set, 'Categoria cancellata con sucesso', res.status);
    } catch (error: any) {
      setSnackbarError(set, error);
    }
    set(categoryLoaderState, false);
    return res;
  };
