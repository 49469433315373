import {
  SnackBarClass,
  removeNullishOrEmptyFields,
  snackBarState,
} from '@laborability/commons';
import { COLORS, LBTButton } from '@laborability/components';
import { Grid, Stack } from '@mui/material';
import { Formik, FormikProps, FormikValues } from 'formik';
import { ReactElement, ReactNode, useEffect, useRef } from 'react';
import Loader from './Loader';
import { useRecoilState } from 'recoil';

interface Props<T> {
  defaultValues: T;
  currentValues?: T;
  isLoading: boolean;
  validation: (values: T) => Partial<{ [K in keyof T]: string }>;
  handleSubmit: (values: T) => Promise<any>;
  resetGridState: (params?: any) => Promise<any>;
  resetFormState: () => void;
  children: (params: FormikProps<T>) => ReactNode;
  customButtons?: ((params: FormikProps<T>) => ReactElement)[];
}

export default function ModalForm<T extends FormikValues>({
  defaultValues,
  currentValues,
  validation,
  handleSubmit,
  resetGridState,
  resetFormState,
  children,
  customButtons,
  isLoading,
}: Props<T>) {
  const errorRef = useRef((_: any) => {});
  const isEditForm = !!currentValues?.id;
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);

  useEffect(() => {
    errorRef.current(snackBar.fields);
  }, [snackBar.fields]);

  useEffect(() => {
    return () => setSnackBar(new SnackBarClass());
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...defaultValues,
        /* serve perché i null dal be rompono i campi di input, 
        sovrascrivo la classe con solo i dati che mi interessano */
        ...removeNullishOrEmptyFields(currentValues),
      }}
      validate={validation}
      onSubmit={async (values, { setSubmitting }) => {
        await handleSubmit(values);
        setSubmitting(false);
        await resetGridState();
      }}
    >
      {params => {
        errorRef.current = params.setErrors;
        return (
          <>
            {isLoading ? (
              <Loader />
            ) : (
              <form onSubmit={params.handleSubmit} style={{ height: '100%' }}>
                <Grid
                  container
                  spacing={2}
                  style={{ overflow: 'auto', maxHeight: '70vh' }}
                >
                  {children(params)}
                </Grid>
                <Stack
                  justifyContent={isEditForm ? 'space-between' : 'right'}
                  direction="row"
                  display="flex"
                  paddingTop={2}
                  bgcolor={COLORS.getInstance().WHITE}
                >
                  {isEditForm && (
                    <LBTButton
                      variant="contained"
                      color="primary"
                      disabled={params.isSubmitting}
                      sx={{ marginRight: '8px' }}
                      onClick={() => {
                        params.resetForm();
                        resetFormState();
                      }}
                    >
                      Nuovo
                    </LBTButton>
                  )}
                  {customButtons ? (
                    <Stack flexDirection="row">
                      {customButtons.map(item => {
                        return item(params);
                      })}
                    </Stack>
                  ) : (
                    <LBTButton
                      type="submit"
                      variant="contained"
                      disabled={params.isSubmitting}
                      onClick={() => {}}
                    >
                      {isEditForm ? 'Modifica' : 'Registra'}
                    </LBTButton>
                  )}
                </Stack>
              </form>
            )}
          </>
        );
      }}
    </Formik>
  );
}
